import { CompaniesAdd } from "./Add";
import { CompaniesList } from "./List";
import { useLocation, useNavigate } from "react-router-dom";
import { CompanyRole } from "src/shared/models/UserCompany";
import { removeInStorageUserPreferences, setInStorage, setInStorageUserPreferences } from "src/shared/utils/storage";

import { useCallback, useEffect, useState } from "react";

import { Trans, useTranslation } from "react-i18next";

import {
    getAuthUserPreferencePreferredCompanyId,
    getAuthUserPreferenceSelectedCompanyId,
} from "src/modules/auth/selectors";
import {
    setAuthUserPreferencePreferredCompanyId,
    setAuthUserPreferenceSelectedCompanyId,
} from "src/modules/auth/slice";
import { retrieveAuthCompanyInformations } from "src/modules/auth/thunkActions";
import { getAuthCompanies, getAuthCompaniesLoading } from "src/modules/authCompanies/selectors";
import { fetchCompaniesAuthUser } from "src/modules/authCompanies/thunkActions";
import { fetchLoanOffers, fetchLoans } from "src/modules/loans/thunkActions";
import { useAppDispatch, useAppSelector } from "src/store";

import { Alert } from "src/shared/atoms/Alert/Alert";
import { Button } from "src/shared/atoms/Buttons/Button";
import { Checkbox } from "src/shared/atoms/Checkbox/Checkbox";
import { Icon } from "src/shared/atoms/Icons/Icon";
import { ToolTip } from "src/shared/atoms/Tooltip/Tooltip";
import { PageHeader } from "src/shared/components/PageHeader/PageHeader";

import "./styles.scss";

export function CompaniesPanel() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [companyIndex, setCompanyIndex] = useState<number | null>(null);
    const [isChangedPreferredCompany, setIsChangedPreferredCompany] = useState<boolean>(false);
    const { t } = useTranslation();

    const authCompanies = useAppSelector((state) => getAuthCompanies(state));
    const isCompaniesLoading = useAppSelector((state) => getAuthCompaniesLoading(state));
    const selectedCompanyId = useAppSelector((state) => getAuthUserPreferenceSelectedCompanyId(state));
    const preferredCompanyId = useAppSelector((state) => getAuthUserPreferencePreferredCompanyId(state));
    let company = authCompanies && companyIndex !== null ? authCompanies[companyIndex] : null;
    const [preferredCompanyCheck, setPreferredCompanyCheck] = useState(false);

    const location = useLocation();
    const noRedirection = location.state?.noRedirection;
    const hasOneCompany = authCompanies.length === 1 && authCompanies[0].hasAccess && !!authCompanies[0].role;

    useEffect(() => {
        if (!authCompanies.length) {
            dispatch(fetchCompaniesAuthUser());
        }
    }, [dispatch]);

    useEffect(() => {
        if (noRedirection) {
            setCompanyIndex(authCompanies.findIndex((company) => company.id === preferredCompanyId));
            setPreferredCompanyCheck(!!preferredCompanyId);
        }
    }, [noRedirection]);

    useEffect(() => {
        if (hasOneCompany) {
            dispatch(setAuthUserPreferenceSelectedCompanyId({ companyId: authCompanies[0].id }));
            setInStorage("currentCompanyId", authCompanies[0].id);
            dispatch(retrieveAuthCompanyInformations());
            redirectToDashboard();
        }
    }, [hasOneCompany, company]);

    useEffect(() => {
        const selectedCompanyIndex = authCompanies?.findIndex((company) => company.id === selectedCompanyId);

        if (selectedCompanyIndex !== null && selectedCompanyIndex >= 0) {
            setCompanyIndex(selectedCompanyIndex);
        }
    }, [authCompanies, selectedCompanyId]);

    useEffect(() => {
        if (preferredCompanyId && !noRedirection) {
            setInStorage("currentCompanyId", preferredCompanyId);
            dispatch(setAuthUserPreferenceSelectedCompanyId({ companyId: preferredCompanyId }));
            dispatch(setAuthUserPreferencePreferredCompanyId({ companyId: preferredCompanyId }));
            dispatch(retrieveAuthCompanyInformations());
            redirectToDashboard();
        }
    }, [preferredCompanyId, noRedirection, dispatch, navigate, company]);

    const handleRedirectToEnterprise = () => {
        if (company) {
            if (isChangedPreferredCompany) {
                if (!preferredCompanyCheck && noRedirection) {
                    dispatch(setAuthUserPreferencePreferredCompanyId({ companyId: null }));
                    removeInStorageUserPreferences("preferredCompanyId");
                } else if (preferredCompanyCheck) {
                    dispatch(setAuthUserPreferencePreferredCompanyId({ companyId: company.id }));
                    setInStorageUserPreferences("preferredCompanyId", company.id);
                }
            }
            dispatch(setAuthUserPreferenceSelectedCompanyId({ companyId: company.id }));
            setInStorage("currentCompanyId", company.id);
            dispatch(retrieveAuthCompanyInformations());
            if (company.role === CompanyRole.ADMIN) {
                dispatch(fetchLoans({}));
                dispatch(fetchLoanOffers({}));
            }
            redirectToDashboard();
        }
    };

    const redirectToDashboard = () => {
        if (company) {
            navigate(company?.role === CompanyRole.ADMIN_ONLY ? "/admin" : "/dashboard");
        }
    };

    const handleChangePreferredCompanyCheck = () => {
        setPreferredCompanyCheck(!preferredCompanyCheck);
        setIsChangedPreferredCompany(true);
    };

    useEffect(() => {
        if (
            (companyIndex !== null &&
                companyIndex !== authCompanies.findIndex((company) => company.id === preferredCompanyId)) ||
            companyIndex === null
        ) {
            setPreferredCompanyCheck(false);
        } else {
            setPreferredCompanyCheck(true);
        }
    }, [companyIndex]);

    const handleGlobalKeyPress = useCallback(
        (event: KeyboardEvent) => {
            if (event.key === "Enter") {
                handleRedirectToEnterprise();
            } else if (event.key === "Tab") {
                event.preventDefault();
                const nextIndex = companyIndex === null ? 0 : (companyIndex + 1) % authCompanies.length;
                setCompanyIndex(nextIndex);
            }
        },
        [companyIndex, authCompanies.length, handleRedirectToEnterprise]
    );

    useEffect(() => {
        document.addEventListener("keydown", handleGlobalKeyPress);

        return () => document.removeEventListener("keydown", handleGlobalKeyPress);
    }, [companyIndex, authCompanies.length, handleRedirectToEnterprise]);

    return (
        <div className='companies-container'>
            {!isCompaniesLoading && !hasOneCompany && (
                <>
                    <PageHeader title={t<string>("Companies.my-companies")} />
                    <CompaniesList
                        isLoading={isCompaniesLoading}
                        companyIndex={companyIndex}
                        setCompanyIndex={setCompanyIndex}
                    />
                    <CompaniesAdd />
                    <div className='action-items-container'>
                        <div className='checkbox-text-container'>
                            <Checkbox
                                checked={preferredCompanyCheck}
                                label={t("CommonUse.define-as-favorite")}
                                onChange={handleChangePreferredCompanyCheck}
                                testId='checkbox-define-as-favorite'
                                className='fw-bold'
                            />
                            <ToolTip
                                activation
                                content={<Trans t={t}>{t("Companies.win-time")}</Trans>}
                                children={<Icon name='information-circle' />}
                                position='right'
                            />
                        </div>
                        <Button
                            label={t("CommonUse.start-operation")}
                            disabled={companyIndex === null}
                            onClick={handleRedirectToEnterprise}
                            testId='button-start-operation'
                        />
                    </div>
                    {preferredCompanyCheck ? <Alert message={t("Companies.define-as-favorite-description")} /> : null}
                </>
            )}
        </div>
    );
}
