import { BillingPayBillPaymentAmount } from "./Amount";
import { BillsTransfertDetails } from "./Details";
import { BillsTransfertRecap } from "./Recap";
import { BillsSuccessModalContent } from "./SuccessModal";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { requestPartnerDetails } from "src/services/billing/biller/operations";
import { BillingBiller } from "src/services/billing/biller/type";
import { requestWalletsBeneficiaries } from "src/services/wallets/operations";
import { WalletApi, WalletBeneficiary } from "src/services/wallets/types";
import { HandleSubmitPropsPasswordValidation, PasswordValidationModal } from "src/shared/common/Password/Modal";
import { TransactionNotify } from "src/shared/models/Task";
import { CompanyStatus } from "src/shared/models/UserCompany";
import { formatMoneyToString } from "src/shared/utils/formatMoney";
import { removeExtraSpaces } from "src/shared/utils/removeExtraSpaces";
import { v4 as uuidv4 } from "uuid";

import { useEffect, useMemo, useState } from "react";

import { useTranslation } from "react-i18next";

import { getAuthUser, getAuthUserExpensePolicyByWallet } from "src/modules/auth/selectors";
import { fetchAuthUserExpensePolicies } from "src/modules/auth/thunkActions";
import { useBillerWithExternalReference } from "src/modules/billing/billers/hooks";
import { usePopulateFacturationState } from "src/modules/billing/billingTransactions/hooks";
import { getTransactionsState } from "src/modules/transactions/selectors";
import { BillReferenceFields, handleCreateBillTransaction } from "src/modules/transactions/thunkActions";
import { useWalletDetails } from "src/modules/wallets/hooks";
import { getSendWalletLoading, getSendWallets } from "src/modules/wallets/selectors";
import { useAppDispatch, useAppSelector } from "src/store";

import { TransferAlert } from "src/components/Transfert/Alert/Alert";
import { WalletSelectionDrawer } from "src/components/WalletSelectionDrawer/Drawer";
import { Button } from "src/shared/atoms/Buttons/Button";
import { Icon } from "src/shared/atoms/Icons/Icon";
import { DrawerInput } from "src/shared/atoms/Inputs/Drawer/Input";
import { Spinner } from "src/shared/atoms/Spinner/Spinner";
import { Typography } from "src/shared/atoms/Typography/Typography";
import { StepAccordion } from "src/shared/components/Accordion/StepAccordion/StepAccordion";
import { BlockedAccountAlert } from "src/shared/components/BlockedAccountAlert/BlockedAccountAlert";
import { KybAlert } from "src/shared/components/KybAlert/KybAlert";

import "./styles.scss";

const retrieveErrorMatchPattern = (fields: BillReferenceFields, biller?: BillingBiller) => {
    const errors: { [key: string]: string } = {};

    Object.entries(biller?.billerFields ?? {})?.forEach(([fieldName, data]) => {
        const value = fields[fieldName as keyof BillReferenceFields];
        const patternWithoutSize = data.patern.split("{").shift() ?? "";

        if (
            !(
                ((!value || value?.length === 0) && !data.required) ||
                new RegExp(`^${patternWithoutSize.replace(/\.\*/, "")}+$`).test(value ?? "")
            )
        ) {
            errors[fieldName] = `Bills.field-error${patternWithoutSize.includes("a-zA-Z") ? "-letters" : ""}${
                patternWithoutSize.includes("0-9") ? "-numbers" : ""
            }${patternWithoutSize.includes("-_.+*?") ? "-specials" : ""}`;
        }
    });

    return errors;
};

export enum FACTURATION_TRANSFERT_STEPS {
    DEBIT_SELECT,
    EXTERNAL_REFERENCE,
    AMOUNT,
}

type LocationState = {
    preselectedWalletId?: string;
};

export function PayBillPaymentPanel() {
    const { t } = useTranslation();
    const { billerReference } = useParams<{ billerReference: string }>();

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const [step, setStep] = useState(0);
    const [open, setOpen] = useState(false);
    const [transactionSuccess, setTransactionSuccess] = useState(false);
    const [debitDrawerOpen, setDebitDrawerOpen] = useState(false);
    const [selectedDebitWallet, setSelectedDebitWallet] = useState<WalletApi | null>(null);
    const [fields, setFields] = useState<BillReferenceFields>({});
    const [amount, setAmount] = useState(0);
    const [comment, setComment] = useState("");
    const [errors, setErrors] = useState<{ [key: string]: string }>({});
    const [loading, setLoading] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [beneficiariesLoading, setBeneficiariesLoading] = useState(false);
    const [beneficiaries, setBeneficiaries] = useState<WalletBeneficiary[] | undefined>();
    const [createdTransaction, setCreatedTransaction] = useState<undefined | TransactionNotify>();

    const taskUuid = useMemo(() => {
        return uuidv4().toString();
    }, []);

    const { makeTransactionError } = useAppSelector((state) => getTransactionsState(state));
    const wallets = useAppSelector((state) => getSendWallets(state));
    const walletsLoading = useAppSelector((state) => getSendWalletLoading(state));
    const { biller, loading: billerLoading } = useBillerWithExternalReference({
        walletId: selectedDebitWallet?.id,
        externalReference: billerReference ?? "",
        typeSlug: beneficiaries?.[0]?.typeSlug ?? "bill-payment",
        serviceSlug: beneficiaries?.[0]?.serviceSelected.serviceSlug ?? "",
    });

    const providedState = useLocation().state as LocationState;
    const isValidProvidedState = !!wallets.find((wallet) => wallet.id === Number(providedState?.preselectedWalletId));
    const wallet = useWalletDetails(selectedDebitWallet?.id);
    const expensePolicy = useAppSelector((state) =>
        getAuthUserExpensePolicyByWallet(state, { walletId: selectedDebitWallet?.id })
    );

    const authUser = useAppSelector((state) => getAuthUser(state));
    const isBlockedAccount = !!(authUser && authUser.Company?.status !== CompanyStatus.BLOCK_0);

    usePopulateFacturationState({
        setAmount,
        setComment,
        setSelectedDebitWallet,
        setStep,
    });

    const expenseWalletData = useMemo(
        () =>
            expensePolicy && selectedDebitWallet?.id
                ? expensePolicy.spentMoneyPerWallet.find((item) => item.walletId === selectedDebitWallet.id)
                : null,
        [expensePolicy, selectedDebitWallet]
    );

    const spentMoney = expenseWalletData?.spentMoney ?? 0;
    const remainingBalance = expensePolicy ? expensePolicy?.limitPerUnitOfTime - spentMoney : 0;

    const requiredFieldsFill = useMemo(
        () =>
            biller?.billerFields
                ? Object.entries(biller.billerFields)
                      .filter(([, data]) => data.required && data.display)
                      ?.every(([fieldName]) => fields[fieldName as keyof BillReferenceFields])
                : true,
        [biller, fields]
    );

    useEffect(() => {
        if (wallets.length === 1) {
            setSelectedDebitWallet(wallets[0]);
        }
    }, [wallets]);

    useEffect(() => {
        if (providedState?.preselectedWalletId && isValidProvidedState) {
            setSelectedDebitWallet(
                wallets.find((wallet) => wallet.id === Number(providedState.preselectedWalletId)) ?? null
            );
            handleToExternalReference();
        } else {
            handleOpenDebitDrawer();
        }
    }, [providedState?.preselectedWalletId]);

    useEffect(() => {
        if ((!wallets.length && !walletsLoading) || (!biller && !billerLoading)) {
            navigate("/billing/pay-bill");
        }
    }, [biller, billerLoading, navigate, wallets, walletsLoading]);

    useEffect(() => {
        dispatch(fetchAuthUserExpensePolicies());
    }, [dispatch]);

    useEffect(() => {
        if (selectedDebitWallet) {
            handleToExternalReference();
        }
    }, [selectedDebitWallet]);

    const handleToExternalReference = async () => {
        if (selectedDebitWallet && biller) {
            setBeneficiariesLoading(true);

            await requestWalletsBeneficiaries({
                walletId: selectedDebitWallet?.id,
                query: biller?.reference,
                typeSlug: biller?.typeSlug ?? "",
                serviceSlug: biller?.serviceSlug ?? "",
                way: "out",
            })
                .then((res) => {
                    setBeneficiaries(res);
                    handleOpenAccordion(FACTURATION_TRANSFERT_STEPS.EXTERNAL_REFERENCE)();
                })
                .finally(() => setBeneficiariesLoading(false));
        }
    };

    const handleOpenDebitDrawer = () => {
        setDebitDrawerOpen(!debitDrawerOpen);
    };

    const handleSelectDebitAccount = (account: WalletApi) => {
        setSelectedDebitWallet(account);
        setDebitDrawerOpen(false);
    };

    const handleOpenAccordion = (step: FACTURATION_TRANSFERT_STEPS) => () => {
        setStep(step);
    };

    const handleFetchBill = () => {
        setErrors({});
        setDisabled(false);
        setLoading(true);

        const billerFieldsMatchPatern = biller?.billerFields
            ? Object.entries(biller?.billerFields)?.every(([fieldName, data]) => {
                  const value = fields[fieldName as keyof BillReferenceFields];
                  const patternWithoutSize = data.patern.split("{").shift() ?? "";

                  return (
                      ((!value || value?.length === 0) && (!data.required || !data.display)) ||
                      new RegExp(`^${patternWithoutSize.replace(/\.\*/, "")}+$`).test(value ?? "")
                  );
              })
            : true;

        if (!billerFieldsMatchPatern) {
            const newErrors = retrieveErrorMatchPattern(fields, biller);
            setErrors(newErrors);
            setLoading(false);
            return;
        }

        if (selectedDebitWallet && billerReference) {
            const computedFields = Object.entries(fields)
                .filter(([_, value]) => value)
                .reduce<{ [key: string]: string }>((res, [fieldName, value]) => {
                    res[fieldName] = value;
                    return res;
                }, {});

            const serviceSelected =
                beneficiaries?.[0]?.services?.find((service) => {
                    return service.serviceSlug === billerReference;
                }) ?? beneficiaries?.[0]?.serviceSelected;

            requestPartnerDetails({
                walletId: selectedDebitWallet.id,
                typeSlug: beneficiaries?.[0]?.typeSlug ?? "",
                serviceSlug: serviceSelected?.serviceSlug ?? "",
                batchCustomId: taskUuid,
                externalReference: billerReference,
                ...computedFields,
            })
                .then((res) => {
                    if (res.amountToPay) {
                        setAmount(res.amountToPay);
                        setDisabled(true);
                    }
                    setStep(FACTURATION_TRANSFERT_STEPS.AMOUNT);
                })
                .catch((error) => {
                    setErrors({ message: error.message });
                })
                .finally(() => setLoading(false));
        }
    };

    const requiredFieldDisabled = useMemo(() => !requiredFieldsFill, [requiredFieldsFill]);

    const formDisabled = useMemo(
        () =>
            loading ||
            !selectedDebitWallet ||
            !requiredFieldsFill ||
            !amount ||
            amount > (selectedDebitWallet?.balance ?? 0),

        [amount, loading, requiredFieldsFill, selectedDebitWallet]
    );

    const handleSwitchOpenDialog = () => {
        setOpen((oldOpen) => !oldOpen);
    };

    const taskSignUniqueId = useMemo(() => {
        return uuidv4().toString();
    }, []);

    const handleSubmit = async ({ password, twoFactor }: HandleSubmitPropsPasswordValidation) => {
        if (selectedDebitWallet) {
            const willCreateTask = (wallet?.canInit && remainingBalance < amount) ?? false;

            await dispatch(
                handleCreateBillTransaction({
                    willCreateTask,
                    selectedDebitWallet,
                    taskUuid,
                    amount,
                    password,
                    twoFactor,
                    comment,
                    typeSlug: beneficiaries?.[0]?.typeSlug ?? "bill-payment",
                    serviceSlug: beneficiaries?.[0]?.serviceSelected.serviceSlug ?? "",
                    externalReference: billerReference,
                    externalFullname: removeExtraSpaces(biller?.name ?? ""),
                    uniqueId: taskSignUniqueId,
                    ...fields,
                })
            )
                .unwrap()
                .then((res) => {
                    if (res) {
                        setCreatedTransaction(res);
                    }
                    setTransactionSuccess(true);
                });
        }
    };

    const handleRedirect = () => {
        if (selectedDebitWallet?.id) {
            if (!createdTransaction?.isSent) {
                navigate("/tasks", { state: { taskId: createdTransaction?.id } });
            } else {
                navigate("/dashboard", { state: { walletId: selectedDebitWallet.id } });
            }
        }
    };

    useEffect(() => {
        const handleGlobalEnterPress = (event: any) => {
            if (event.key === "Enter") {
                switch (step) {
                    case FACTURATION_TRANSFERT_STEPS.DEBIT_SELECT:
                        if (selectedDebitWallet) {
                            handleToExternalReference();
                        } else {
                            handleOpenDebitDrawer();
                        }
                        break;
                    case FACTURATION_TRANSFERT_STEPS.EXTERNAL_REFERENCE:
                        if (!requiredFieldDisabled) {
                            handleFetchBill();
                        }
                        break;
                    case FACTURATION_TRANSFERT_STEPS.AMOUNT:
                        if (amount && !open) {
                            handleSwitchOpenDialog();
                        }
                        break;
                    default:
                        break;
                }
            }
        };

        document.addEventListener("keydown", handleGlobalEnterPress);

        return () => {
            document.removeEventListener("keydown", handleGlobalEnterPress);
        };
    });

    return (
        <>
            <div className='bills-container'>
                <BlockedAccountAlert />
                <KybAlert />
                {(biller || billerLoading) && (
                    <div className='bills-transfert-biller'>
                        {billerLoading ? (
                            <Spinner size='sm' />
                        ) : (
                            biller && (
                                <div className='reference-name-container'>
                                    <Icon name='kiosk' color='lilas' />
                                    <Typography message={biller.name} className='fw-bold' />
                                    <div className='dot-container'>
                                        <div className='dot' />
                                    </div>
                                    <Typography message={biller.reference} className='fw-bold color-neutral-500' />
                                </div>
                            )
                        )}
                    </div>
                )}
                <div className='transfert-container'>
                    <StepAccordion
                        title={t("TransfertPanel.choisissez-un-compte-a-debiter")}
                        step={FACTURATION_TRANSFERT_STEPS.DEBIT_SELECT}
                        subTitle={
                            step > FACTURATION_TRANSFERT_STEPS.DEBIT_SELECT && selectedDebitWallet !== null
                                ? selectedDebitWallet.label +
                                  " - " +
                                  formatMoneyToString({ amount: selectedDebitWallet.balance })
                                : undefined
                        }
                        currentStep={step}
                        onOpen={handleOpenAccordion(FACTURATION_TRANSFERT_STEPS.DEBIT_SELECT)}
                        footer={
                            <Button
                                className='continue-button'
                                variant='primary'
                                label={t("CommonUse.continue")}
                                onClick={handleToExternalReference}
                                loading={beneficiariesLoading}
                                disabled={!selectedDebitWallet || beneficiariesLoading || isBlockedAccount}
                            />
                        }
                    >
                        <DrawerInput
                            disabled={beneficiariesLoading || isBlockedAccount}
                            label={
                                !selectedDebitWallet
                                    ? t<string>("TransfertPanel.choisissez-un-compte")
                                    : selectedDebitWallet.label + " - " + selectedDebitWallet.balance + " FCFA"
                            }
                            handleClickDrawerInput={handleOpenDebitDrawer}
                            icon='wallet'
                        />
                    </StepAccordion>
                    <StepAccordion
                        title={t("Bills.indicate-references")}
                        step={FACTURATION_TRANSFERT_STEPS.EXTERNAL_REFERENCE}
                        subTitle={
                            step > FACTURATION_TRANSFERT_STEPS.EXTERNAL_REFERENCE ? fields.payerReference : undefined
                        }
                        currentStep={step}
                        onOpen={handleOpenAccordion(FACTURATION_TRANSFERT_STEPS.EXTERNAL_REFERENCE)}
                        footer={
                            <Button
                                className='continue-button'
                                variant='primary'
                                label={t("CommonUse.continue")}
                                onClick={handleFetchBill}
                                loading={loading}
                                disabled={requiredFieldDisabled || loading}
                            />
                        }
                    >
                        {selectedDebitWallet && (
                            <BillsTransfertDetails
                                biller={biller}
                                fields={fields}
                                errors={errors}
                                setFields={setFields}
                            />
                        )}
                    </StepAccordion>
                    <StepAccordion
                        title={t("Bills.fill-the-amount")}
                        step={FACTURATION_TRANSFERT_STEPS.AMOUNT}
                        currentStep={step}
                        onOpen={handleOpenAccordion(FACTURATION_TRANSFERT_STEPS.AMOUNT)}
                        footer={
                            <Button
                                className='continue-button'
                                variant='primary'
                                label={t("CommonUse.continue")}
                                onClick={handleSwitchOpenDialog}
                                disabled={formDisabled}
                            />
                        }
                    >
                        {selectedDebitWallet && (
                            <>
                                <BillsTransfertRecap
                                    beneficiary={beneficiaries?.[0]}
                                    debitAccount={selectedDebitWallet}
                                    amount={amount}
                                />
                                <BillingPayBillPaymentAmount
                                    debitAccount={selectedDebitWallet}
                                    amount={amount}
                                    comment={comment}
                                    setAmount={setAmount}
                                    setComment={setComment}
                                    disabled={disabled}
                                />
                                <TransferAlert walletId={selectedDebitWallet?.id} />
                            </>
                        )}
                    </StepAccordion>
                </div>
                <WalletSelectionDrawer
                    handleSelectedWallet={handleSelectDebitAccount}
                    isOpen={debitDrawerOpen}
                    onClose={handleOpenDebitDrawer}
                    walletType='SEND'
                    disableZeroBalance
                />
            </div>
            <PasswordValidationModal
                header={{
                    title: t("CommonUse.validate"),
                    icon: "lock",
                }}
                successComponent={
                    selectedDebitWallet ? (
                        <BillsSuccessModalContent
                            totalAmountDischarged={amount}
                            currentAccountBalance={selectedDebitWallet?.balance ?? 0}
                            walletId={selectedDebitWallet?.id ?? 0}
                            createdTransaction={createdTransaction}
                        />
                    ) : (
                        <Typography
                            className='color-error fw-bold'
                            message={t(
                                "TransfertPanel.oups-il-y-a-eu-un-probleme-veuillez-reesayer-ou-contactez-le-support"
                            )}
                        />
                    )
                }
                asyncFromParent
                error={makeTransactionError}
                succeeded={transactionSuccess}
                open={open}
                setOpen={handleSwitchOpenDialog}
                handleOnSubmit={handleSubmit}
                onSuccess={handleRedirect}
            />
        </>
    );
}
