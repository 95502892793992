import { AnalyticsDateRanges } from "../Container";
import { BarDatum, ResponsiveBar } from "@nivo/bar";
import moment from "moment";
import { formatMoneyToString } from "src/shared/utils/formatMoney";

import { useEffect, useMemo, useState } from "react";

import { useTranslation } from "react-i18next";

import { Avatar } from "src/shared/atoms/Avatar/Avatar";
import { IconListType } from "src/shared/atoms/Icons/IconList";
import { Typography } from "src/shared/atoms/Typography/Typography";
import { IconButton } from "src/shared/components/IconButton/IconButton";
import { SelectMultipleOptions, SelectOption } from "src/shared/components/SelectMultipleOptions/SelectMultipleOptions";

import "./styles.scss";

type CollectorBarChartProps = {
    data: readonly BarDatum[] | null;
    keys: readonly string[];
    indexBy: string;
    colors: string[];
    header: { title: string; icon: IconListType };
    selectedDateRange?: AnalyticsDateRanges;
};

export const CollectorBarChart = ({
    data,
    keys,
    indexBy,
    colors,
    header,
    selectedDateRange,
}: CollectorBarChartProps) => {
    const { t } = useTranslation();
    const defaultItemsPerPage = 5;
    const [currentPage, setCurrentPage] = useState(0);
    const [selectedCollectors, setSelectedCollectors] = useState<string[]>([]);

    const collectorsAsSelectOption: SelectOption[] = useMemo(() => {
        if (!data) return [];
        return data.map((item, index) => ({
            id: index.toString(),
            label: String(item.collectorName),
        }));
    }, [data]);

    const selectedData = useMemo(() => {
        if (!data || selectedCollectors.length === 0) return [];
        return data.filter((_, index) => selectedCollectors.includes(index.toString()));
    }, [data, selectedCollectors]);

    const otherData = useMemo(() => {
        if (!data) return [];
        return data.filter((_, index) => !selectedCollectors.includes(index.toString()));
    }, [data, selectedCollectors]);

    const totalPages = useMemo(() => {
        const selectedDataCount = selectedData.length;
        const otherDataCount = otherData.length;
        let firstPageItemCount = selectedDataCount > defaultItemsPerPage ? selectedDataCount : defaultItemsPerPage;
        const remainingOtherDataCount = otherDataCount - Math.max(0, firstPageItemCount - selectedDataCount);
        const otherPagesCount = Math.ceil(remainingOtherDataCount / defaultItemsPerPage);
        return 1 + otherPagesCount;
    }, [selectedData, otherData, defaultItemsPerPage]);

    const slicedData = useMemo(() => {
        if (!data) return null;

        const selectedDataCount = selectedData.length;
        let itemsPerPage = defaultItemsPerPage;

        if (currentPage === 0) {
            if (selectedDataCount > defaultItemsPerPage) {
                itemsPerPage = selectedDataCount;
            }

            const firstPageData = [...selectedData];

            if (selectedDataCount < itemsPerPage) {
                const itemsNeeded = itemsPerPage - selectedDataCount;
                firstPageData.push(...otherData.slice(0, itemsNeeded));
            }

            return firstPageData;
        } else {
            const itemsOnFirstPage = selectedDataCount > defaultItemsPerPage ? selectedDataCount : defaultItemsPerPage;
            const adjustedStartIndex =
                (currentPage - 1) * defaultItemsPerPage + Math.max(0, itemsOnFirstPage - selectedDataCount);
            return otherData.slice(adjustedStartIndex, adjustedStartIndex + defaultItemsPerPage);
        }
    }, [currentPage, data, selectedData, otherData, defaultItemsPerPage]);

    const isPrevDisabled = currentPage === 0;
    const isNextDisabled = currentPage >= totalPages - 1;

    const handleClickButton = (direction: "prev" | "next") => () => {
        if (direction === "prev") {
            setCurrentPage((prevPage) => Math.max(prevPage - 1, 0));
        } else if (direction === "next") {
            setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages - 1));
        }
    };

    const handleSelectUser = (user: SelectOption[]) => {
        setSelectedCollectors(user.map((u) => u.id));
    };

    useEffect(() => {
        setCurrentPage(0);
    }, [selectedCollectors]);

    return (
        <div className='collector-bar-chart-container'>
            <div className='collector-bar-chart-header'>
                <div className='text-header'>
                    <Avatar icon={header.icon} size='md' />
                    <Typography message={header.title} className='fw-bold' size='sm' />
                </div>
                <div className='buttons-container'>
                    <SelectMultipleOptions
                        color='white'
                        items={collectorsAsSelectOption}
                        selected={collectorsAsSelectOption.filter((collector) =>
                            selectedCollectors.includes(collector.id)
                        )}
                        onSelectionChange={handleSelectUser}
                        name={
                            selectedCollectors.length === 0
                                ? t("Analytics.collector-to-compare")
                                : t("Analytics.selected-collectors", {
                                      length: selectedCollectors.length,
                                  })
                        }
                        showSelectedItems={false}
                        disabled={data === null}
                    />
                    <div className='buttons'>
                        <IconButton
                            handleOnClick={handleClickButton("prev")}
                            disabled={isPrevDisabled}
                            iconProps={{ name: "arrow-left" }}
                        />
                        <IconButton
                            handleOnClick={handleClickButton("next")}
                            disabled={isNextDisabled}
                            iconProps={{ name: "arrow-right" }}
                        />
                    </div>
                </div>
            </div>
            <div className='collector-bar-chart-content'>
                {slicedData === null || slicedData.length === 0 ? (
                    <Typography message={t("Analytics.no-data")} />
                ) : (
                    <ResponsiveBar
                        colors={colors}
                        data={slicedData}
                        keys={keys}
                        indexBy={indexBy}
                        margin={{ top: 50, right: 60, bottom: 70, left: 60 }}
                        padding={0.3}
                        valueScale={{ type: "linear" }}
                        indexScale={{ type: "band", round: true }}
                        borderColor={{
                            from: "color",
                            modifiers: [["darker", 1.6]],
                        }}
                        minValue={0}
                        theme={{
                            text: {
                                fontSize: 14,
                                fontWeight: "bold",
                            },
                        }}
                        labelSkipWidth={12}
                        labelSkipHeight={12}
                        labelTextColor={{
                            from: "color",
                            modifiers: [["brighter", 10]],
                        }}
                        animate={true}
                        label={(d) => `${formatMoneyToString({ amount: Number(d.value) })}`}
                        legends={[
                            {
                                dataFrom: "keys",
                                anchor: "bottom-left",
                                direction: "row",
                                justify: false,
                                translateX: 0,
                                translateY: 60,
                                itemsSpacing: 2,
                                itemWidth: 100,
                                itemHeight: 20,
                                itemDirection: "left-to-right",
                                itemOpacity: 0.85,
                                symbolSize: 20,
                            },
                        ]}
                        role='application'
                        ariaLabel='Nivo bar chart'
                        barAriaLabel={(e) => `${e.id}: ${e.formattedValue} in country: ${e.indexValue}`}
                        tooltip={({ data }) => (
                            <div className='collector-bar-chart-hover-window'>
                                {selectedDateRange?.value !== "today" ? (
                                    <Typography
                                        message={t(
                                            "Analytics.period-from-to",
                                            selectedDateRange?.value !== "threeLastMonth"
                                                ? {
                                                      minDate: selectedDateRange?.minDate.format("DD MMMM YYYY"),
                                                      maxDate: selectedDateRange?.maxDate.format("DD MMMM YYYY"),
                                                  }
                                                : {
                                                      minDate: selectedDateRange?.minDate.format("MMMM YYYY"),
                                                      maxDate: selectedDateRange?.maxDate.format("MMMM YYYY"),
                                                  }
                                        )}
                                    />
                                ) : (
                                    <Typography message={moment().format("DD MMMM YYYY")} />
                                )}
                                <Typography
                                    message={t("Analytics.collector-name", {
                                        collectorName: data.collectorName,
                                    })}
                                />
                                <div className='divider' />
                                <div className='item greyed'>
                                    <Typography message='Wave' />
                                    <Typography
                                        message={formatMoneyToString({
                                            amount: data.Wave,
                                        })}
                                    />
                                </div>
                                <div className='item greyed'>
                                    <Typography message='XPRESS' />
                                    <Typography
                                        message={formatMoneyToString({
                                            amount: data.XPRESS,
                                        })}
                                    />
                                </div>
                                <div className='item'>
                                    <Typography message={t("Analytics.total")} />
                                    <Typography
                                        message={formatMoneyToString({
                                            amount: Number(data.Wave) + Number(data.XPRESS),
                                        })}
                                    />
                                </div>
                            </div>
                        )}
                    />
                )}
            </div>
        </div>
    );
};
