import classNames from "classnames";
import { WalletApi } from "src/services/wallets/types";
import { formatMoneyToString } from "src/shared/utils/formatMoney";
import { DrawerContext } from "src/wrapper/context";

import { SyntheticEvent, useContext } from "react";

import { useTranslation } from "react-i18next";

import { getUserCountryTag } from "src/modules/auth/selectors";
import { SubscriptionRestrictions, useSubscriptionRestrictions } from "src/shared/hooks/useSubscriptionRestrictions";
import { useAppSelector } from "src/store";

import { Avatar } from "src/shared/atoms/Avatar/Avatar";
import { IconListType } from "src/shared/atoms/Icons/IconList";
import { Typography } from "src/shared/atoms/Typography/Typography";

import "./styles.scss";

type Props = {
    handleRedirect?: () => void;
    wallet?: WalletApi;
    icon: IconListType;
};

export function DashboardListItem({ handleRedirect, wallet, icon }: Props) {
    const { t } = useTranslation();
    const { setOpen, setProvidedState } = useContext(DrawerContext);

    const userCountryCode = useAppSelector((state) => getUserCountryTag(state));

    const handleOpenIbanDrawer = (event: SyntheticEvent) => {
        event.stopPropagation();
        setOpen("iban");
        setProvidedState({ walletId: String(wallet?.id ?? "") });
    };

    const isCountryDissalowed = ["BEN"].includes(userCountryCode ?? "");

    const isWalletRestricted = useSubscriptionRestrictions(SubscriptionRestrictions.RESTRICTIONS_WALLET);

    return wallet ? (
        <div
            onClick={isWalletRestricted && !wallet.isDefault ? () => {} : handleRedirect}
            className={classNames("dashboard-list-item-container", {
                disabled: isWalletRestricted && !wallet.isDefault ? true : false,
            })}
            data-testid={`company-list-item-${wallet.id}`}
        >
            <div className='list-item-container'>
                <div className='title-container'>
                    <Typography className='fw-bold' message={wallet.label} />
                    <div className='dot' />
                    <Typography message={wallet.reference} />
                </div>
                <div className='iban-button-container'>
                    <Avatar icon={icon} size='sm' />
                    {wallet.iban && !isCountryDissalowed ? (
                        <div
                            className='iban-button'
                            data-testid={`company-list-item-iban-${wallet.id}`}
                            onClick={handleOpenIbanDrawer}
                        >
                            <Typography message={t("Iban.see-bank-details")} />
                        </div>
                    ) : (
                        <Typography message={t("Wallets.wallet")} className='color-neutral' />
                    )}
                </div>
            </div>

            <Typography className='money-display' message={`${formatMoneyToString({ amount: wallet.balance ?? 0 })}`} />
        </div>
    ) : null;
}
