import { Icon, IconProps } from "../Icons/Icon";
import { IconListType } from "../Icons/IconList";
import { Typography } from "../Typography/Typography";
import clsx from "classnames";

import { ImgHTMLAttributes, ReactNode, useEffect, useRef, useState } from "react";

import "./styles.scss";

export type SelectTypes = "primary" | "success" | "warning" | "error";

type SelectType = {
    header: {
        component?: ReactNode;
        title?: string;
        placeholder?: string;
        icon?: IconListType;
        iconProps?: IconProps;
        dropdownIcon?: IconListType;
        disabled?: boolean;
        className?: string;
        image?: ImgHTMLAttributes<HTMLImageElement>;
    };
    dropDownContent: {
        header?: { title: ReactNode; label?: ReactNode };
        body: ReactNode;
        footer?: ReactNode;
    };
};

export type SelectProps = {
    className?: string;
    position?: "right" | "left";
    content: SelectType;
    contentClassName?: string;
    type?: SelectTypes;
    onBlur?: () => void;
    fitContent?: true;
    forceClose?: boolean;
    disabled?: boolean;
    color?: "grey" | "white" | "darkgrey";
    overflowAuto?: boolean;
    testId?: string;
};

export const Select = ({
    className = "",
    contentClassName = "",
    position = "left",
    content,
    type = "primary",
    color = "grey",
    onBlur,
    fitContent,
    forceClose,
    disabled = false,
    overflowAuto,
    testId,
}: SelectProps) => {
    const [showItem, setShowItem] = useState(false);
    const selectRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (forceClose) {
            setShowItem(false);
        }
    }, [forceClose]);

    const handleShownItem = () => {
        if (content.header.disabled || disabled) return;
        if (showItem) {
            onBlur && onBlur();
        }
        setShowItem(!showItem);
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (selectRef.current && !selectRef.current.contains(event.target as Node)) {
            setShowItem(false);
        }
    };

    useEffect(() => {
        document.addEventListener("click", handleClickOutside, true);
        return () => {
            document.removeEventListener("click", handleClickOutside, true);
        };
    }, []);

    return (
        <div className={`select-container ${className}`} ref={selectRef} data-testid='select-container'>
            <div className={"select"}>
                <div
                    className={clsx(`select-header type-${type} header-color-${color} ${content.header.className}`, {
                        "variant-icon-menu": content.header.dropdownIcon && !content.header.title,
                        disabled: content.header.disabled,
                        show: showItem,
                        hide: !showItem,
                    })}
                    onClick={handleShownItem}
                    data-testid={testId}
                >
                    <div className='title-overflow'>
                        {content.header.icon && <Icon name={content.header.icon} {...content.header.iconProps} />}
                        {content.header.image && <img alt='select-header-icon' {...content.header.image} />}
                        {content.header.title && (
                            <Typography
                                className={clsx(`${content.header.className ?? ""}`, {
                                    "color-neutral-400": disabled,
                                })}
                                message={content.header.title}
                            />
                        )}
                        {!content.header.title && content.header.placeholder && (
                            <Typography className='color-grey' message={content.header.placeholder} />
                        )}
                        {content.header.component ? content.header.component : null}
                    </div>
                    <Icon
                        name={content.header.dropdownIcon ? content.header.dropdownIcon : "chevron-up"}
                        className={clsx({
                            show: !showItem && !content.header.dropdownIcon,
                            "color-neutral-400": disabled,
                        })}
                    ></Icon>
                </div>
                {!content.header.disabled && (
                    <div
                        className={clsx(`select-content ${contentClassName}`, {
                            "fit-content": fitContent,
                            "full-width": !fitContent,
                            "show fadeInUp": showItem,
                            left: position === "left",
                            hide: !showItem,
                        })}
                    >
                        {showItem && (
                            <>
                                {content.dropDownContent.header && (
                                    <div className='content-header'>
                                        <Typography
                                            className='fw-bold'
                                            message={content.dropDownContent.header.title}
                                        />
                                        {content.dropDownContent.header.label && content.dropDownContent.header.label}
                                    </div>
                                )}
                                <div
                                    className={clsx(`content-body ${contentClassName}`, {
                                        bordered: !content.dropDownContent.header && !content.dropDownContent.footer,
                                        "overflow-auto": overflowAuto,
                                    })}
                                >
                                    {content.dropDownContent.body}
                                </div>
                                {content.dropDownContent.footer && (
                                    <div className='content-footer'>{content.dropDownContent.footer}</div>
                                )}
                            </>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};
