import { requestGetLoanTransactions } from "src/services/loans/operations";
import { TransactionApi } from "src/services/transactions/types";
import { LoanCurrent } from "./Current/Current";
import { LoanHistory } from "./History/History";
import { LoanSimulator } from "./Simulator/Simulator";

import { useEffect, useState } from "react";

import {
    getActiveLoan,
    getClosedLoans,
    getLoanOffers,
    getLoanOffersLoading,
    getLoans,
    getLoansLoading,
} from "src/modules/loans/selectors";
import { fetchLoanOffers, fetchLoans } from "src/modules/loans/thunkActions";
import { useAppDispatch, useAppSelector } from "src/store";

import "./styles.scss";

export const LoanPanel = () => {
    const dispatch = useAppDispatch();

    const loanOffers = useAppSelector((state) => getLoanOffers(state));
    const loans = useAppSelector((state) => getLoans(state));
    const activeLoan = useAppSelector((state) => getActiveLoan(state));
    const closedLoans = useAppSelector((state) => getClosedLoans(state));
    const isLoadingLoan = useAppSelector((state) => getLoansLoading(state));
    const isLoadingLoanOffers = useAppSelector((state) => getLoanOffersLoading(state));

    //const [disburseTransactions, setDisburseTransactions] = useState<TransactionApi[]>([]);
    const [historyTransactions, setHistoryTransactions] = useState<TransactionApi[]>([]);

    useEffect(() => {
        dispatch(fetchLoans({}));
        dispatch(fetchLoanOffers({ filters: { status: ["to-propose", "pending"] } }));
    }, []);

    const isLoading = isLoadingLoan || isLoadingLoanOffers;

    useEffect(() => {
        if (loans.length > 0) {
            requestGetLoanTransactions({ loanUuid: loans[0].uuid }).then((response) => {
                setHistoryTransactions(response.data);
            });
            // requestGetLoanTransactions({ offerId: loans[0].offerId.toString() }).then((response) => {
            //     setDisburseTransactions(response.data);
            // });
        }
    }, [loans]);

    return (
        <div className='loan-panel-container'>
            {!isLoading && (
                <>
                    {activeLoan ? (
                        <LoanCurrent currentLoan={activeLoan} historyTransactions={historyTransactions || []} />
                    ) : (loanOffers.length > 0 ? (
                        <LoanSimulator
                            isEligible={true}
                            maxRange={loanOffers[0].amount}
                            interest={loanOffers[0].fees}
                            date={loanOffers[0].createdAt}
                        />
                    ) : (
                        <LoanSimulator
                            isEligible={false}
                            maxRange={0}
                            interest={0}
                            date={loanOffers.length ? loanOffers[0].createdAt : null}
                        />
                    ))}
                    {closedLoans.length > 0 ? (
                        <LoanHistory loanHistory={closedLoans} />
                    ) : (
                        <LoanHistory loanHistory={[]} />
                    )}
                </>
            )}
            {isLoading && (
                <>
                    <div className='placeholder' />
                    <div className='placeholder' />
                </>
            )}
        </div>
    );
};
