import { TasksDetailsDrawerContainer } from "../Details/Drawer/Container";
import { useNavigate } from "react-router-dom";
import { requestGetTask, requestMarkTaskAsComplete } from "src/services/tasks/operations";
import { TaskListApiReturnItem } from "src/services/tasks/types";
import { PasswordValidationModal } from "src/shared/common/Password/Modal";
import { computeTimestampToString } from "src/shared/utils/formatDate";
import { formatMoneyToString } from "src/shared/utils/formatMoney";

import { useState } from "react";

import { useTranslation } from "react-i18next";

import { useRetryTaskValidationPassword } from "src/modules/tasks/hooks";
import {
    getTasksCurrentPage,
    getTasksIsLoading,
    getTasksList,
    getTasksShowPageSize,
    getTasksTotalCount,
} from "src/modules/tasks/selectors";
import { receiveTask, setTaskCurrentPage, setTaskPageSize } from "src/modules/tasks/slice";
import { useAppDispatch, useAppSelector } from "src/store";

import { Avatar } from "src/shared/atoms/Avatar/Avatar";
import { Button } from "src/shared/atoms/Buttons/Button";
import { Icon } from "src/shared/atoms/Icons/Icon";
import { StatusIcon } from "src/shared/atoms/Icons/StatusIcons";
import { Typography } from "src/shared/atoms/Typography/Typography";
import { Empty } from "src/shared/components/Empty/Empty";
import { Modal } from "src/shared/components/Modal/Modal";
import { SelectableTable, SelectableTableRow } from "src/shared/components/SelectableTable/SelectableTable";

import "./styles.scss";

const emptyListImg = require("src/shared/images/empty/empty-user.svg").default;

export function TasksListContainer() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [selectedTaskId, setSelectedTaskId] = useState<number | null>(null);

    const tasks = useAppSelector((state) => getTasksList(state));
    const isLoading = useAppSelector((state) => getTasksIsLoading(state));
    const totalCount = useAppSelector((state) => getTasksTotalCount(state));
    const currentPage = useAppSelector((state) => getTasksCurrentPage(state));
    const pageSize = useAppSelector((state) => getTasksShowPageSize(state));

    const [open, setOpen] = useState(false);
    const [markAsCompleteLoading, setMarkAsCompleteLoading] = useState(false);

    const { error, handleRetryTasksTransaction, handleSwitchPasswordDialogOpen, passwordDialogOpen } =
        useRetryTaskValidationPassword(String(selectedTaskId));

    const handleSetCurrentPage = (newCurrentPage: number) => {
        dispatch(setTaskCurrentPage({ currentPage: newCurrentPage }));
    };

    const handleSetPageSize = (newPageSize: number) => {
        dispatch(setTaskPageSize({ pageSize: newPageSize }));
    };

    const handleSetSelectedTaskId = (newTaskId: number) => () => {
        setSelectedTaskId(newTaskId);
    };

    const handleResetSelectedTaskId = () => {
        setSelectedTaskId(null);
    };

    const handleRedirect = (task: TaskListApiReturnItem) => () => {
        navigate("/dashboard", { state: { walletId: task.walletId, taskId: task.id } });
    };

    const selectedTask = selectedTaskId ? tasks[selectedTaskId] : null;

    const handleSwitchDialog = () => {
        setOpen((oldOpen) => !oldOpen);
    };

    const handleSubmit = () => {
        if (!selectedTask) {
            return;
        }
        setMarkAsCompleteLoading(true);
        requestMarkTaskAsComplete(selectedTask.id)
            .then(async () => {
                setMarkAsCompleteLoading(false);
                const task = await requestGetTask(selectedTask.id);
                setOpen(false);
                dispatch(receiveTask({ task }));
            })
            .finally(() => setMarkAsCompleteLoading(false));
    };

    const tableRows: SelectableTableRow[][] = Object.values(tasks)
        .reverse()
        .map((task) => {
            const { title, metadata, Initiator, createdAt, id } = task;

            const nbSuccess = metadata?.nbSuccess ?? 0;
            const nbPending = metadata?.nbPending ?? 0;
            const nbFailed = metadata?.nbFailed ?? 0;
            const sum = metadata?.sum ?? 0;
            const fees = metadata?.fees ?? 0;

            return [
                {
                    content: (
                        <div className='center-item' onClick={handleSetSelectedTaskId(id)}>
                            <Avatar icon='task' />
                        </div>
                    ),
                    type: "image",
                    onClick: handleSetSelectedTaskId(id),
                },
                {
                    content: (
                        <div className='d-flex align-items-center'>
                            <div className='d-flex flex-column'>
                                <Typography className='fw-bold' message={title} />
                                <Typography
                                    className='color-neutral-400 fw-bold'
                                    message={`
                              ${Initiator?.firstname ?? ""} ${Initiator?.lastname ?? ""}
                            (${computeTimestampToString(createdAt, "DD/MM/YY HH:mm:s")})`}
                                />
                            </div>
                        </div>
                    ),
                    type: "text",
                    onClick: handleSetSelectedTaskId(id),
                },
                {
                    content: (
                        <div className='d-flex w-100 justify-content-center'>
                            <Typography className='fw-bold' message={nbPending} />
                        </div>
                    ),
                    type: "status",
                    onClick: handleSetSelectedTaskId(id),
                },
                {
                    content: (
                        <div className='d-flex w-100 justify-content-center'>
                            <Typography className='fw-bold' message={nbFailed} />
                        </div>
                    ),
                    type: "status",
                    onClick: handleSetSelectedTaskId(id),
                },
                {
                    content: (
                        <div className='d-flex w-100 justify-content-center mr-4'>
                            <Typography className='fw-bold' message={nbSuccess} />
                        </div>
                    ),
                    type: "status",
                    onClick: handleSetSelectedTaskId(id),
                },
                {
                    content: (
                        <div className='d-flex ml-1'>
                            <StatusIcon withColor status={task.status} />
                        </div>
                    ),
                    type: "status",
                    onClick: handleSetSelectedTaskId(id),
                },
                {
                    content: (
                        <Typography
                            className='fw-bold color-error-500'
                            message={`${formatMoneyToString({ amount: sum })}`}
                        />
                    ),
                    type: "text",
                    onClick: handleSetSelectedTaskId(id),
                },
                {
                    content: <Typography className='fw-bold' message={`${formatMoneyToString({ amount: fees })}`} />,
                    type: "text",
                    onClick: handleSetSelectedTaskId(id),
                },
                {
                    content: (
                        <div className='task-list-buttons-container'>
                            {task?.status !== "sending" && (
                                <Button icon='list-bullet' variant='tertiary' onClick={handleRedirect(task)} />
                            )}
                        </div>
                    ),
                    type: "button",
                    onClick: handleSetSelectedTaskId(id),
                },
            ];
        });

    return (
        <>
            <div className='task-list-table'>
                <SelectableTable
                    isLoading={isLoading}
                    pagination={{
                        currentPage,
                        totalCount,
                        pageSize: pageSize,
                        onPageChange: handleSetCurrentPage,
                        onPageSizeChange: handleSetPageSize,
                    }}
                    header={[
                        "",
                        <Typography
                            className='color-neutral-600 fw-bold details-cell'
                            message={t("CommonUse.operation-details")}
                        />,
                        <Icon name='clock' />,
                        <Icon name='x-circle' />,
                        <Icon name='check-circle' />,
                        <Typography className='color-neutral-600 fw-bold' message={t("CommonUse.status")} />,
                        <Typography className='color-neutral-600 fw-bold' message={t("CommonUse.amount")} />,
                        <Typography className='color-neutral-600 fw-bold' message={t("CommonUse.fees")} />,
                        <></>,
                    ]}
                    empty={
                        <Empty
                            image={emptyListImg}
                            title={t("Table.no-result-found")}
                            description={t<string>("Table.no-result-found-description")}
                        />
                    }
                    rows={tableRows as SelectableTableRow[][]}
                />
            </div>
            {selectedTask && (
                <TasksDetailsDrawerContainer
                    open={!!selectedTask}
                    task={selectedTask}
                    handleSwitchOpen={handleResetSelectedTaskId}
                />
            )}
            <PasswordValidationModal
                error={error}
                handleOnSubmit={handleRetryTasksTransaction}
                setOpen={handleSwitchPasswordDialogOpen}
                open={passwordDialogOpen}
            />
            <Modal
                open={open}
                loading={markAsCompleteLoading}
                handleOnSubmit={handleSubmit}
                handleOnCancel={handleSwitchDialog}
                header={{ title: t("Tasks.mark-as-complete"), icon: "arrow-path" }}
            >
                <Typography className='color-neutral-400' message={t("Tasks.mark-as-complete-warning")} />
            </Modal>
        </>
    );
}
