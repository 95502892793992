import { isTaskItemTmpModel } from "../tasksItems/thunkActions";
import { fetchTask, fetchTaskToEdit, fetchTasks } from "./thunkActions";
import { generateTaskExcelFile } from "./utils";
import { useNavigate, useParams } from "react-router-dom";
import { requestGetTaskItems } from "src/services/taskItems/operations";
import { requestGetTasksList, requestRetryTaskTransactions } from "src/services/tasks/operations";
import { TaskListApiReturnItem } from "src/services/tasks/types";
import { requestGetWalletTransactions } from "src/services/transactions/operations";
import { TransactionApi } from "src/services/transactions/types";
import { HandleSubmitPropsPasswordValidation } from "src/shared/common/Password/Modal";
import { TaskItemTmpModel } from "src/shared/models/TaskItemTmp";
import { DrawerContext } from "src/wrapper/context";

import { useContext, useEffect, useMemo, useState } from "react";

import { getWalletIds } from "../wallets/selectors";
import { getTask, getTaskToEdit, getWalletTasksAsSelectOption } from "./selectors";
import { useAppDispatch, useAppSelector } from "src/store";

import { SelectOption } from "src/shared/components/SelectMultipleOptions/SelectMultipleOptions";

type RouteParams = {
    taskId: string;
};

export function useRouterTaskToEdit() {
    const dispatch = useAppDispatch();
    const { taskId } = useParams<keyof RouteParams>() as RouteParams;

    useEffect(() => {
        if (taskId) {
            dispatch(fetchTaskToEdit({ taskId }));
        }
    }, [dispatch, taskId]);

    return useAppSelector((state) => getTaskToEdit(state));
}

export function useRouterTask() {
    const dispatch = useAppDispatch();
    const { taskId } = useParams<keyof RouteParams>() as RouteParams;

    useEffect(() => {
        if (taskId) {
            dispatch(fetchTask({ taskId }));
        }
    }, [dispatch, taskId]);

    return useAppSelector((state) => getTask(state, { taskId: parseInt(taskId) }));
}

export function useTasksOptionsForAuthorizedWallets(selectedWallets: string[], minDate?: string, maxDate?: string) {
    const [tasks, setTasks] = useState<TaskListApiReturnItem[] | undefined>();
    const [loading, setLoading] = useState(true);

    const walletOptions = useAppSelector((state) => getWalletIds(state));

    useEffect(() => {
        requestGetTasksList({
            page: -1,
            wallets: walletOptions,
            status: ["cancelled", "complete", "pending", "refund-failed", "refund-pending", "refunded", "to-retry"],
            minDate,
            maxDate,
        }).then((res) => {
            setLoading(false);
            setTasks(res.data);
        });
    }, [walletOptions, minDate, maxDate]);

    return useMemo(
        () => ({
            tasksOptions: selectedWallets.reduce<SelectOption[]>((res, selectedWallet) => {
                if (tasks) {
                    tasks
                        .filter((task) => selectedWallet === String(task.walletId))
                        .forEach((task) => res.push({ id: String(task.id), label: task.title }));
                }
                return res;
            }, []),
            loading,
        }),
        [loading, selectedWallets, tasks]
    );
}

export function useTasksOptionsForWallet(walletId: string) {
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(
            fetchTasks({
                filters: {
                    wallets: [walletId],
                    page: -1,
                    status: [
                        "cancelled",
                        "complete",
                        "pending",
                        "sending",
                        "refund-failed",
                        "refund-pending",
                        "refunded",
                        "to-retry",
                    ],
                },
            })
        );
    }, [dispatch, walletId]);

    return useAppSelector((state) => getWalletTasksAsSelectOption(state, { walletId }));
}

export function useRetryTaskValidationPassword(taskId: string) {
    const dispatch = useAppDispatch();
    const [error, setError] = useState();
    const [passwordDialogOpen, setPasswordDialogOpen] = useState(false);

    const handleSwitchPasswordDialogOpen = () => {
        setPasswordDialogOpen(!passwordDialogOpen);
    };

    const handleRetryTasksTransaction = async ({ password, twoFactor }: HandleSubmitPropsPasswordValidation) => {
        setError(undefined);

        return requestRetryTaskTransactions({ password, twoFactor, taskId })
            .then(() => {
                return dispatch(fetchTask({ taskId })).then(() => {
                    return Promise.resolve(setPasswordDialogOpen(false));
                });
            })
            .catch((error) => {
                setError(error.message);
                return Promise.reject(error);
            });
    };

    return { error, passwordDialogOpen, handleRetryTasksTransaction, handleSwitchPasswordDialogOpen };
}

type UseDuplicateTaskProps = {
    task: TaskListApiReturnItem;
    handleSwitchOpen: () => void;
    open: boolean;
};

export function useExportAsExcelOrDuplicateTask({ task, open, handleSwitchOpen }: UseDuplicateTaskProps) {
    const navigate = useNavigate();
    const { setOpen, setProvidedState } = useContext(DrawerContext);

    const [taskItems, setTaskItems] = useState<TransactionApi[] | TaskItemTmpModel[]>();

    const transaction = taskItems?.[0];

    const duplicateDisabled = transaction
        ? isTaskItemTmpModel(transaction)
            ? transaction?.typeSlug?.includes("loan-repayment")
            : transaction?.TransactionType?.slug.includes("loan-repayment")
        : false;

    useEffect(() => {
        const fetchTaskItems = async () => {
            if (open && task) {
                let res;
                if (["draft", "to-validate"].includes(task.status ?? "")) {
                    res = await requestGetTaskItems(task.id);
                } else {
                    res = await requestGetWalletTransactions(String(task.walletId), {
                        taskIds: [String(task.id)],
                        page: -1,
                    });
                }
                setTaskItems(res.data);
            }
        };
        fetchTaskItems();
    }, [open]);

    const handleDuplicateTask = async () => {
        handleSwitchOpen();
        const isRapidTransfert =
            taskItems?.length === 1 &&
            transaction &&
            (isTaskItemTmpModel(transaction)
                ? transaction.serviceSlug.includes("transfer")
                : transaction.Service.slug.includes("transfer"));
        const isFacturation =
            taskItems?.length === 1 &&
            transaction &&
            (isTaskItemTmpModel(transaction)
                ? transaction?.typeSlug?.includes("bill-payment")
                : transaction.TransactionType.slug.includes("bill-payment"));

        if (isRapidTransfert) {
            navigate("/transfert/rapid-transfert", {
                state: {
                    amount: transaction.amount,
                    selectedDebitAccountId: task.walletId,
                    selectedCreditAccountExternalReference: transaction.externalReference,
                    selectedCreditAccountServiceSlug: isTaskItemTmpModel(transaction)
                        ? transaction.serviceSlug
                        : transaction.Service.slug,
                    comment: transaction.comment,
                },
            });
        } else if (isFacturation) {
            navigate(
                `/billing/pay-bill/${
                    isTaskItemTmpModel(transaction) ? transaction.serviceSlug : transaction.Service.slug
                }`,
                {
                    state: {
                        amount: transaction.amount,
                        ...(isTaskItemTmpModel(transaction)
                            ? {}
                            : {
                                  invoiceReference: transaction.invoiceReference,
                                  payerReference: transaction.payerReference,
                              }),
                        selectedDebitAccountId: task.walletId,
                        comment: transaction.comment,
                        ...(isTaskItemTmpModel(transaction)
                            ? {
                                  ...(transaction.externalReference
                                      ? { externalReference: transaction.externalReference }
                                      : {}),
                                  ...(transaction.invoiceReference
                                      ? { invoiceReference: transaction.invoiceReference }
                                      : {}),
                                  ...(transaction.payerReference ? { payerReference: transaction.payerReference } : {}),
                              }
                            : {}),
                    },
                }
            );
        } else {
            setOpen("createTask");
            setProvidedState({ duplicateTask: task });
        }
    };

    const handleDownloadTaskAsExcel = async () => {
        if (taskItems?.length) {
            generateTaskExcelFile(taskItems, task);
        }
    };

    return { duplicateDisabled, handleDuplicateTask, handleDownloadTaskAsExcel };
}
