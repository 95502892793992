import { RootState } from "src/store";

const getLoansState = (state: RootState) => state.loans;

export const getLoans = (state: RootState) => getLoansState(state).loans;

export const getActiveLoan = (state: RootState) => {
    const loans = getLoansState(state).loans;
    return loans.find((loan) => loan.status.includes("active") || loan.status.includes("approved"));
};

export const getClosedLoans = (state: RootState) => {
    const loans = getLoansState(state).loans;
    return loans.filter((loan) => loan.status.includes("closed"));
};

export const getLoansLoading = (state: RootState) => getLoansState(state).isLoadingLoans;

export const getLoanOffers = (state: RootState) => getLoansState(state).loanOffers;
export const getLoanOffersLoading = (state: RootState) => getLoansState(state).isLoadingLoanOffers;

export const areFetchedLoans = (state: RootState) => getLoansState(state).initialFetchDone;
