import { LoanModel, LoanOfferModel } from "src/shared/models/Loan";

import { createSlice } from "@reduxjs/toolkit";

interface LoansState {
    isLoadingLoans: boolean;
    loans: LoanModel[];

    isLoadingLoanOffers: boolean;
    loanOffers: LoanOfferModel[];

    initialFetchDone?: boolean;
}

const initialState: LoansState = {
    isLoadingLoans: false,
    loans: [],

    isLoadingLoanOffers: false,
    loanOffers: [],

    initialFetchDone: false,
};

export const loansSlice = createSlice({
    name: "loans",
    initialState,
    reducers: {
        requestLoans: (state) => {
            return {
                ...state,
                isLoadingLoans: true,
                initialFetchDone: true,
            };
        },
        failedRequestLoans: (state) => {
            return {
                ...state,
                isLoadingLoans: false,
                initialFetchDone: false,
            };
        },
        receiveLoans: (state, { payload }: { payload: { loans: LoanModel[] } }) => {
            const { loans } = payload;

            return {
                ...state,
                isLoadingLoans: false,
                loans,
            };
        },

        requestLoanOffers: (state) => {
            return {
                ...state,
                isLoadingLoanOffers: true,
                initialFetchDone: true,
            };
        },
        failedRequestLoanOffers: (state) => {
            return {
                ...state,
                isLoadingLoanOffers: false,
                initialFetchDone: false,
            };
        },
        receiveLoanOffers: (state, { payload }: { payload: { loanOffers: LoanOfferModel[] } }) => {
            const { loanOffers } = payload;

            return {
                ...state,
                isLoadingLoanOffers: false,
                loanOffers,
            };
        },
    },
});

export const {
    requestLoans,
    failedRequestLoans,
    receiveLoans,
    requestLoanOffers,
    failedRequestLoanOffers,
    receiveLoanOffers,
} = loansSlice.actions;

export const loansSliceReducer = loansSlice.reducer;
