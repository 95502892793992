import { ReceiptsModal } from "./ReceiptsModal";
import { TransactionMultipleActions } from "./Table";
import { useParams } from "react-router-dom";
import {
    refundTransactions,
    requestDownloadReceipts,
    requestRetryTransaction,
} from "src/services/transactions/operations";
import { TransactionApi } from "src/services/transactions/types";
import { PasswordValidationModal } from "src/shared/common/Password/Modal";
import { moneyOutCancelReasonsOptions } from "src/shared/const/transactions";
import { CompanyRole } from "src/shared/models/UserCompany";
import { formatMoneyToString } from "src/shared/utils/formatMoney";

import { Dispatch, SetStateAction, useMemo, useState } from "react";

import { useTranslation } from "react-i18next";

import { useIsUserAuthorized } from "src/shared/hooks/useIsUserAuthorized";

import { Alert } from "src/shared/atoms/Alert/Alert";
import { IconListType } from "src/shared/atoms/Icons/IconList";
import { Input } from "src/shared/atoms/Inputs/Input";
import { Typography } from "src/shared/atoms/Typography/Typography";
import { Modal } from "src/shared/components/Modal/Modal";
import { SelectOption } from "src/shared/components/SelectMultipleOptions/SelectMultipleOptions";
import { SimpleSelect } from "src/shared/components/SimpleSelect/SimpleSelect";

const successImage = require("src/shared/images/user/success.png");

const modalTitles = {
    receipts: "Transactions.download-multiple-receipts",
    retry: "Transactions.retry-multiple-transactions",
    "cancel-reason": "Transactions.cancel-multiple-transactions",
    cancel: "Transactions.cancel-multiple-transactions",
};

const modalIcons: { [key in TransactionMultipleActions]: IconListType } = {
    receipts: "document",
    retry: "retry",
    "cancel-reason": "retry",
    cancel: "forbidden",
};

type RouteParams = {
    walletId: string;
    taskId?: string;
};

type Props = {
    action?: TransactionMultipleActions;
    loading: boolean;
    transactions: TransactionApi[];
    selectedRows?: number[];
    setLoading: Dispatch<SetStateAction<boolean>>;
    setAction: Dispatch<SetStateAction<TransactionMultipleActions | undefined>>;
    setIsLoadingReceipt: (isLoading: boolean) => void;
    handleFetchWalletTransactions?: () => void;
    walletId?: number;
};

export function HistoryMultipleActions({
    action,
    transactions,
    loading,
    selectedRows,
    setLoading,
    setAction,
    setIsLoadingReceipt,
    handleFetchWalletTransactions,
    walletId,
}: Readonly<Props>) {
    const { t } = useTranslation();
    const { walletId: routeWalletId } = useParams<keyof RouteParams>() as RouteParams;

    const [error, setError] = useState<string>();
    const [reason, setReason] = useState<SelectOption>();
    const [openReceiptsModal, setOpenReceiptsModal] = useState(false);
    const [comment, setComment] = useState<string>("");

    const isUserUnauthorized = useIsUserAuthorized({ allowedRoles: [CompanyRole.READ] });

    const filteredTransactions = useMemo(() => {
        switch (action) {
            case "cancel-reason":
            case "cancel": {
                return selectedRows?.map((index) => {
                    const transaction = transactions?.[index];

                    const createdAtDate = new Date(transaction?.createdAt);
                    const threeDaysAgo = new Date();
                    threeDaysAgo.setDate(threeDaysAgo.getDate() - 3);

                    const isOlderThanThreeDays = createdAtDate < threeDaysAgo;
                    const disabled = isUserUnauthorized || isOlderThanThreeDays;
                    return transaction?.canCancel && !transaction?.isCancelled && !disabled ? transaction : null;
                });
            }
            case "retry": {
                return selectedRows?.map((index) => {
                    const transaction = transactions?.[index];
                    return transaction?.canRetry ? transaction : null;
                });
            }
            case "receipts": {
                if (selectedRows) {
                    if (selectedRows.length > 1 && selectedRows.length !== 0) {
                        setOpenReceiptsModal(true);
                    } else if (selectedRows.length === 1) {
                        document.body.classList.add("cursor-wait");
                        setIsLoadingReceipt(true);
                        requestDownloadReceipts({
                            transactionsUuids: [transactions[selectedRows[0]]?.uuid ?? ""],
                            recipients: [],
                        })
                            .then((downloadLink: any) => {
                                const link = document.createElement("a");
                                link.href = downloadLink.url;
                                link.setAttribute("download", "receipt.pdf");
                                document.body.appendChild(link);
                                link.click();
                                document.body.removeChild(link);
                                setAction(undefined);
                            })
                            .finally(() => {
                                document.body.classList.remove("cursor-wait");
                                setIsLoadingReceipt(false);
                            });
                    }
                }
                return selectedRows?.map((index) => transactions?.[index]).filter(Boolean);
            }
            default: {
                return selectedRows?.map((index) => {
                    const transaction = transactions?.[index];
                    return transaction;
                });
            }
        }
    }, [selectedRows, action, transactions])?.filter((transaction) => transaction) as TransactionApi[];

    const transactionsUuids = useMemo(() => {
        return (filteredTransactions?.map((transaction) => transaction.uuid) ?? []) as string[];
    }, [filteredTransactions]);

    const cancelAmount = filteredTransactions?.reduce((res, transaction) => {
        return res + (transaction.amount ?? 0);
    }, 0);

    const cancelAmountFee = cancelAmount * 0.05;

    const reinbursedAmount = cancelAmount - cancelAmountFee;

    const handleChangeReason = (option: SelectOption) => {
        setError(undefined);
        setReason(option);
    };

    const handleRetryReceipts = ({ password, twoFactor }: { password: string; twoFactor: string | null }) => {
        setLoading(true);

        return requestRetryTransaction({ walletId: String(walletId), uuids: transactionsUuids, password, twoFactor })
            .then(() => handleFetchWalletTransactions && handleFetchWalletTransactions())
            .finally(() => setLoading(false));
    };

    const handleCancelPasswordValidation = () => {
        if (!reason) {
            setError("Transactions.select-refund-motif");
            return;
        }
        setAction("cancel");
    };

    const handleCancelReceipts = ({ password, twoFactor }: { password: string; twoFactor: string | null }) => {
        setLoading(true);

        return refundTransactions({
            walletId: walletId ? String(walletId) : routeWalletId,
            uuids: transactionsUuids,
            reason: reason?.id ?? "",
            ...(reason?.id === "OTHER" && { comment }),
            password,
            twoFactor,
        })
            .then(() => handleFetchWalletTransactions && handleFetchWalletTransactions())
            .finally(() => setLoading(false));
    };

    const handleCloseDialog = () => {
        setAction(undefined);
        setError(undefined);
        setOpenReceiptsModal(false);
        setReason(undefined);
    };

    const handleChangeComment = (value: string) => {
        setError(undefined);
        setComment(value);
    };

    return (
        <>
            <Modal
                open={action === "cancel-reason"}
                header={{
                    title: action ? t(modalTitles[action]) : "",
                    icon: "x-bold",
                }}
                cancelLabel={t<string>("CommonUse.close")}
                handleOnSubmit={handleCancelPasswordValidation}
                disableSubmitButton={loading || (reason?.id === "OTHER" && !comment)}
                handleOnCancel={handleCloseDialog}
                size='md'
                confirmLabel={t<string>("CommonUse.continue")}
            >
                <div className='modal-multiple-action-content'>
                    <Alert
                        message={t("Transactions.cancel-modal-title")}
                        subMessage={t<string>("Transactions.refund-description")}
                    />
                    <div className='multiple-action-cancel-container'>
                        <div className='multiple-action-cancel-item'>
                            <Typography message={t("Transactions.transaction-number")} />
                            <Typography message={filteredTransactions?.length} />
                        </div>
                        <div className='multiple-action-cancel-item'>
                            <Typography message={t("Tasks.total-amount")} />
                            <Typography message={formatMoneyToString({ amount: cancelAmount })} />
                        </div>
                        <div className='multiple-action-cancel-item'>
                            <Typography message={t("Transactions.refund-fees")} />
                            <Typography
                                className='color-error-500'
                                message={`5% (${formatMoneyToString({ amount: cancelAmountFee })})`}
                            />
                        </div>
                        <div className='multiple-action-cancel-item'>
                            <Typography message={t("Transactions.refund-estimation")} />
                            <Typography message={formatMoneyToString({ amount: reinbursedAmount })} />
                        </div>
                    </div>
                    <div className='d-flex flex-column'>
                        <Typography className='fw-bold mb-2' message={t("Transactions.refund-motif")} />
                        <SimpleSelect
                            useRadio
                            headerClassname='select-multiple-actions'
                            options={moneyOutCancelReasonsOptions}
                            selected={reason}
                            placeholder={t<string>("Transactions.select-refund-motif")}
                            onSelectionChange={handleChangeReason}
                            variant={error ? "error" : "primary"}
                            underMessage={t<string>("Transactions.select-refund-motif")}
                        />
                        {reason?.id === "OTHER" && (
                            <Input
                                placeholder={t("Transactions.refund-comment")}
                                onChange={handleChangeComment}
                                value={comment}
                                className='mt-2'
                            />
                        )}
                    </div>
                </div>
            </Modal>
            <ReceiptsModal
                onCloseDialog={handleCloseDialog}
                transactionUuids={transactionsUuids}
                open={openReceiptsModal}
            />
            <PasswordValidationModal
                open={action === "cancel" || action === "retry"}
                alert={
                    action === "retry"
                        ? {
                              message: t("Transactions.retry-operations", {
                                  count: transactionsUuids.length,
                              }),
                              color: "warning",
                              icon: "exclamation-circle",
                          }
                        : undefined
                }
                header={{
                    title: action ? t(modalTitles[action]) : "",
                    icon: action ? modalIcons[action] : "academic",
                }}
                successContent={{
                    image: successImage,
                    text: t<string>("CommonUse.request-taken-into-consideration"),
                }}
                handleOnSubmit={action === "cancel" ? handleCancelReceipts : handleRetryReceipts}
                setOpen={handleCloseDialog}
            />
        </>
    );
}
