import { AdminDashboardQuestsSection } from "./Quests/Section";
import { AdminDashboardQuestsTutorialsSection } from "./Quests/Tutorials/Section";
import { StatCard } from "./StatCard";
import { TodoWidget } from "./TodoWidget";

import { useEffect } from "react";

import { useTranslation } from "react-i18next";

import { getAdminDashboardInfoData, getAdminDashboardInfoLoading } from "src/modules/admin/selectors";
import { fetchAdminDashboardInfoData } from "src/modules/admin/thunkActions";
import { useAppDispatch, useAppSelector } from "src/store";

import { EmailValidationAlertPanel } from "src/components/Login/EmailValidation/Alert";
import { BlockedAccountAlert } from "src/shared/components/BlockedAccountAlert/BlockedAccountAlert";
import { KybAlert } from "src/shared/components/KybAlert/KybAlert";

import "./styles.scss";

export const AdminDashboardPanel = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const adminDashboardInfo = useAppSelector((state) => getAdminDashboardInfoData(state));
    const isLoading = useAppSelector((state) => getAdminDashboardInfoLoading(state));

    useEffect(() => {
        if (!adminDashboardInfo) {
            dispatch(fetchAdminDashboardInfoData());
        }
    }, [dispatch]);

    return (
        <div className='dashboard-container'>
            <BlockedAccountAlert />
            <EmailValidationAlertPanel />
            <KybAlert />
            <div className='stat-card-list'>
                <StatCard
                    icon='wallet'
                    label={t("Wallets.wallets")}
                    value={adminDashboardInfo?.nbWallets}
                    isLoading={isLoading}
                    link='/admin/wallets'
                />
                <StatCard
                    icon='user-group'
                    label={t("Users.users")}
                    value={adminDashboardInfo?.nbUsers}
                    isLoading={isLoading}
                    link='/admin/users'
                />
                <StatCard
                    icon='user-check'
                    label={t("Beneficiaries.beneficiaires")}
                    value={adminDashboardInfo?.nbBeneficiaries}
                    isLoading={isLoading}
                    link='/admin/beneficiaries'
                />
            </div>
            <AdminDashboardQuestsSection />
            <AdminDashboardQuestsTutorialsSection />
            <TodoWidget todos={adminDashboardInfo?.todo || []} isLoading={isLoading} />
        </div>
    );
};
