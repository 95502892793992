import { UserCreateDrawerBody } from "./Body";
import { PasswordValidationModal } from "src/shared/common/Password/Modal";
import { CreateUserContext, UserValuesType } from "src/shared/context/createUser";

import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { getCreatedUser, getUserDetail } from "src/modules/admin/userDetail/selectors";
import { clearUserDetailData, handleCreateUser } from "src/modules/admin/userDetail/thunkActions";
import { getAuthUser } from "src/modules/auth/selectors";
import { useAppDispatch, useAppSelector } from "src/store";

import { Drawer } from "src/shared/atoms/Drawer/Drawer";
import { InputPhoneValue } from "src/shared/atoms/Inputs/Phone/Phone";

import "./styles.scss";

type Props = {
    isOpen: boolean;
    attachToWallet?: boolean;
    onCloseDrawer: () => void;
};

export function UserCreateDrawerContainer({ attachToWallet, isOpen, onCloseDrawer }: Props) {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const { userDetail } = useAppSelector(getUserDetail);
    const { loading, error } = useAppSelector(getCreatedUser);
    const user = useAppSelector(getAuthUser);
    const [step, setStep] = useState<number>(0);
    const [userValues, setUserValues] = useState<UserValuesType | undefined>();
    const [phone, setPhone] = useState<InputPhoneValue | null>({
        prefix: user?.countryCode || "00225",
        value: "",
    });
    const [openPasswordDialog, setOpenPassword] = useState(false);
    const [isHidden, setIsHidden] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);

    useEffect(() => {
        dispatch(clearUserDetailData());
        setPhone({
            prefix: user?.countryCode || "00225",
            value: "",
        });
        setStep(0);
        // eslint-disable-next-line
    }, [isOpen]);

    useEffect(() => {
        if (openPasswordDialog) {
            setShowSuccess(false);
        }
    }, [openPasswordDialog]);

    useEffect(() => {
        if (loading === false && !error && userValues) {
            setShowSuccess(true);
            setPhone(null);
            handleClose();
        }
        // eslint-disable-next-line
    }, [error, loading]);

    const handleValidatedStep = (validated: boolean, newStep: number) => {
        if (validated) {
            if (step < newStep) {
                setStep(newStep);
            }
        } else {
            if (step > newStep - 1) {
                setStep(newStep - 1);
            }
        }
    };

    const handleClose = () => {
        initState();
        onCloseDrawer();
        setPhone(null);
        dispatch(clearUserDetailData());
    };

    const initState = () => {
        setUserValues(undefined);
        setStep(0);
    };

    const handleSubmit = async (password: string, otp: string | null) => {
        if (userValues) {
            const args = {
                password,
                otp,
                ...(userDetail && { id: userDetail.id }),
                ...(userValues.addWaveCollect && {
                    collectorInfo: {
                        countryCode: userValues.customWavePhone ? userValues.waveCountryCode : phone?.prefix,
                        phone: userValues.customWavePhone
                            ? (userValues.customWavePhone as string)
                            : userValues.wavePhone || userValues.phone || phone?.value,
                        walletId: userValues.waveWalletId,
                    },
                }),
                ...(userValues.addAccessType && {
                    accessType: userValues.accessType,
                }),

                email: userValues.email || "",
                gender: userValues.gender,
                countryCode: userValues.countryCode || phone?.prefix,
                phone: userValues.phone || phone?.value,
                dateOfBirth: userValues.dateOfBirth,
                countryOfCitizenship: userValues.countryOfCitizenship?.toUpperCase(),
                firstname: userValues.firstname,
                lastname: userValues.lastname,
                passportFront: userValues.passportFront,
                passportBehind: userValues.passportBehind,
                slug: userValues.slug,
                customReference: userValues.customReference,
            };

            await dispatch(handleCreateUser(args));
        }
    };

    const handleSwitchOpenDialog = () => {
        setOpenPassword((oldOpenDialog) => {
            if (oldOpenDialog) {
                setIsHidden(false);
            }
            return !oldOpenDialog;
        });
    };

    const handleOpenPasswordDialog = () => {
        setOpenPassword(true);
        setIsHidden(true);
    };

    const handleConfirm = async ({ password, twoFactor }: { password: string; twoFactor: string | null }) => {
        await handleSubmit(password, twoFactor);
    };

    return (
        <CreateUserContext.Provider
            value={{
                phone,
                setPhone,
                step,
                userValues,
                attachToWallet,
                setStep,
                setUserValues,
                handleValidatedStep,
                initState,
            }}
        >
            <div className='user-create-drawer-container'>
                <Drawer
                    isOpen={isOpen}
                    isHidden={isHidden}
                    onClose={handleClose}
                    header={{ title: t("UserCreateDrawerContainer.ajouter-un-utilisateur"), image: "user-plus" }}
                    body={<UserCreateDrawerBody />}
                    footer={{
                        primaryButton: {
                            label: t("UserCreateDrawerFooter.creer-le-profil"),
                            disabled: step < 3,
                            onClick: handleOpenPasswordDialog,
                        },
                        secondaryButton: { label: t("CommonUse.cancel"), onClick: handleClose },
                        optionalText: `${step}/3 ${t("UserCreateDrawerFooter.informations-completees")}`,
                    }}
                />

                <PasswordValidationModal
                    header={{
                        title: t("UserCreateDrawerContainer.ajouter-un-utilisateur"),
                        icon: "user-plus",
                    }}
                    successContent={{
                        image: require("src/shared/images/user/success.png"),
                        text: t("UserCreateDrawerContainer.votre-demande-a-bien-ete-prise-en-compte").toString(),
                    }}
                    error={error}
                    asyncFromParent
                    succeeded={showSuccess}
                    open={openPasswordDialog}
                    setOpen={handleSwitchOpenDialog}
                    handleOnSubmit={handleConfirm}
                />
            </div>
        </CreateUserContext.Provider>
    );
}
