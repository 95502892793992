import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "../../store";

import { SelectOption } from "src/shared/components/SelectMultipleOptions/SelectMultipleOptions";

const getWalletsState = (state: RootState) => state.wallets;

export const getWallets = createSelector(getWalletsState, (walletsState) => walletsState.wallets);
export const getAuthorizedWallets = createSelector(getWalletsState, (walletsState) => walletsState.authorizedWallets);

export const getAuthorizedWalletsAsSelectOptions = createSelector(getAuthorizedWallets, (wallets) =>
    Object.values(wallets)
        .filter((wallet) => wallet.label)
        .map<SelectOption>((wallet) => ({ id: wallet.id.toString(), label: wallet.label as string }))
);

export const getWalletLoading = createSelector(getWalletsState, (walletsState) => walletsState.requested);
export const getSendWalletLoading = createSelector(getWalletsState, (walletsState) => walletsState.sendWalletsLoading);

export const getBankDisposalWallets = createSelector(getWalletsState, (walletsState) =>
    Object.values(walletsState.bankDisposalWallets)
);

export const getBankDisposalWallet = createSelector(
    getBankDisposalWallets,
    (_state: RootState, { walletId }: { walletId: number | undefined }) => walletId,
    (wallets, walletId) => wallets.find((wallet) => wallet.id === walletId)
);

export const getSendWallets = createSelector(getWalletsState, (walletsState) =>
    Object.values(walletsState.sendWallets)
);

export const getWalletIds = createSelector(getWalletsState, (state) =>
    Object.values(state.wallets).map((wallet) => wallet.id.toString())
);

export const getWallet = createSelector(
    getWallets,
    (_state: RootState, { walletId }: { walletId: number | undefined }) => walletId,
    (wallets, walletId) => Object.values(wallets).find((wallet) => wallet.id === walletId)
);

export const getWalletWithIban = createSelector(getWallets, (wallets) =>
    Object.values(wallets).find((wallet) => wallet.iban)
);

export const getWalletByReference = createSelector(
    getWallets,
    (_state: RootState, { walletReference }: { walletReference: string | undefined }) => walletReference,
    (wallets, walletReference) => Object.values(wallets).find((wallet) => wallet.reference === walletReference)
);

export const getJulayaWallets = createSelector(getWallets, (wallets) => Object.values(wallets));
export const getWalletsLoading = createSelector(getWalletsState, (walletsState) => walletsState.walletsLoading);

export const GetJulayaWalletsTotalBalance = createSelector(getJulayaWallets, (wallets) =>
    wallets.reduce((res, wallet) => (Number(wallet.balance) ? res + Number(wallet.balance) : res), 0)
);

export const getWalletsTotalBalance = createSelector(getWallets, (wallets) =>
    Object.values(wallets).reduce((res, wallet) => (Number(wallet.balance) ? res + Number(wallet.balance) : res), 0)
);

export const getJulayaWalletsAsSelectOptions = createSelector(getJulayaWallets, (wallets) =>
    Object.values(wallets)
        .filter((wallet) => wallet.label)
        .map<SelectOption>((wallet) => ({ id: wallet.id.toString(), label: `${wallet.label} - ${wallet.reference}` }))
);

export const getWalletsAsSelectOptions = createSelector(getJulayaWallets, (wallets) =>
    Object.values(wallets)
        .filter((wallet) => wallet.label)
        .map<SelectOption>((wallet) => ({ id: wallet.id.toString(), label: wallet.label ?? "" }))
);
