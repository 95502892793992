import {
    failedRequestLoanOffers,
    failedRequestLoans,
    receiveLoanOffers,
    receiveLoans,
    requestLoanOffers,
    requestLoans,
} from "./slice";
import {
    LoanApiFilters,
    LoanOfferApiFilters,
    requestGetLoanOffers,
    requestGetLoans,
} from "src/services/loans/operations";

import { createAsyncThunk } from "@reduxjs/toolkit";

export const fetchLoans = createAsyncThunk(
    "fetchLoans",
    async ({ filters }: { filters?: LoanApiFilters }, { dispatch }) => {
        dispatch(requestLoans());
        try {
            const loans = await requestGetLoans(filters);
            dispatch(receiveLoans({ loans: loans.data }));
            return loans;
        } catch (error) {
            dispatch(failedRequestLoans());
            return error;
        }
    }
);

export const fetchLoanOffers = createAsyncThunk(
    "fetchLoanOffers",
    async ({ filters }: { filters?: LoanOfferApiFilters }, { dispatch }) => {
        dispatch(requestLoanOffers());
        try {
            const loanOffers = await requestGetLoanOffers(filters);
            dispatch(receiveLoanOffers({ loanOffers: loanOffers.data }));
            return loanOffers;
        } catch (error) {
            dispatch(failedRequestLoanOffers());
            return error;
        }
    }
);
