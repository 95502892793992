import { EmailVerificationLinkSend } from "./LinkSend";
import { requestUpdateAuthUser, requestVerifyEmail } from "src/services/auth/operations";

import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { getAuthUser } from "src/modules/auth/selectors";
import { setUseBaseRouter } from "src/modules/auth/slice";
import { useAppSelector } from "src/store";

import { Button } from "src/shared/atoms/Buttons/Button";
import { Input } from "src/shared/atoms/Inputs/Input";
import { Typography } from "src/shared/atoms/Typography/Typography";

function isEmailValid(email: string): boolean {
    const emailRegex: RegExp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
}

export const EmailValidationPanel = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const authUser = useAppSelector((state) => getAuthUser(state));

    const [email, setEmail] = useState("");
    const [error, setError] = useState<string | undefined>();
    const [loading, setLoading] = useState(false);
    const [validEmail, setValidEmail] = useState(true);
    const [send, setSend] = useState(false);

    const handleChangeEmail = (newEmail: string) => {
        const isValidEmail = isEmailValid(newEmail);
        setValidEmail(isValidEmail);
        setEmail(newEmail);
    };

    const handleRedirect = () => {
        dispatch(setUseBaseRouter(false));
    };

    const handleSendValidationMail = async () => {
        setLoading(true);
        if (authUser?.email !== email) {
            try {
                await requestUpdateAuthUser({ email });
            } catch (error: any) {
                setLoading(false);
                setError(error.message);
                return;
            }
        }
        await requestVerifyEmail().finally(() =>
            setTimeout(() => {
                setLoading(false);
            }, 30000)
        );
        setSend(true);
    };

    useEffect(() => {
        if (authUser?.email) {
            const isValidEmail = isEmailValid(authUser.email);
            setValidEmail(isValidEmail);
            setEmail(authUser?.email);
        }
    }, [authUser]);

    return send ? (
        <EmailVerificationLinkSend />
    ) : (
        <>
            <Typography variant={"h2"} message={t("LoginPanel.verify-email")} className='login-title-happy' />
            <Typography message={t("LoginPanel.verify-your-email")} className='color-neutral-500 fw-bold' />
            <form className='form-container'>
                <Input
                    label={t<string>("LoginPanel.send-email-to")}
                    placeholder={t<string>("LoginPanel.please-fill-email")}
                    type='classic'
                    name='identifier'
                    value={email}
                    onChange={handleChangeEmail}
                    underMessage={!error && validEmail ? undefined : t<string>("LoginPanel.email-format-incorrect")}
                    variant={validEmail && !error ? undefined : "error"}
                />
                <Typography
                    className='color-neutral-500'
                    message={t<string>("LoginPanel.check-email-before-sending")}
                />
            </form>
            <div className='d-flex'>
                <Button
                    onClick={handleRedirect}
                    className='w-100 mr-2'
                    variant='tertiary'
                    label={t("LoginPanel.verify-later")}
                />
                <Button
                    loading={loading}
                    disabled={loading || !validEmail}
                    onClick={handleSendValidationMail}
                    className='w-100'
                    label={t("LoginPanel.send-link")}
                />
            </div>
        </>
    );
};
