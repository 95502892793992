import { CompanyRole } from "../models/UserCompany";

import { useMemo } from "react";

import { useAuthUserSelectedCompany } from "src/modules/authCompanies/hooks";

type Props = {
    allowedRoles: CompanyRole[];
};

export const useIsUserAuthorized = ({ allowedRoles }: Props) => {
    const userRole = useAuthUserSelectedCompany();

    const isAuthorized = useMemo(() => allowedRoles.some((allowedRole) => userRole?.role === allowedRole), [userRole]);

    return isAuthorized;
};
