import { firstOptionDisplay } from "./Panel";
import { useLocation } from "react-router-dom";
import { TaskStatus } from "src/shared/models/Task";

import { useCallback, useEffect, useMemo, useState } from "react";

import { useTranslation } from "react-i18next";

import {
    getTaskQuery,
    getTaskToSignFilter,
    getTaskWalletReferenceFilter,
    getTasksCurrentPage,
} from "src/modules/tasks/selectors";
import {
    resetTaskFilter,
    resetTaskFilters,
    setTaskWalletQuery,
    setTaskWalletReferenceFilter,
    switchTaskToSignFilter,
} from "src/modules/tasks/slice";
import { FetchTasksFilters, fetchTasks } from "src/modules/tasks/thunkActions";
import { getWalletsAsSelectOptions } from "src/modules/wallets/selectors";
import { fetchWalletsAuthUser } from "src/modules/wallets/thunkActions";
import { useDebounce } from "src/shared/hooks/useDebounce";
import { useAppDispatch, useAppSelector } from "src/store";

import { Button } from "src/shared/atoms/Buttons/Button";
import { Input } from "src/shared/atoms/Inputs/Input";
import { Select } from "src/shared/atoms/Select/Select";
import { ToggleSwitchOption } from "src/shared/atoms/Toggler/Toggler";
import { Typography } from "src/shared/atoms/Typography/Typography";
import { Chip } from "src/shared/components/Chip/Chip";
import { SelectMultipleOptions, SelectOption } from "src/shared/components/SelectMultipleOptions/SelectMultipleOptions";

import "./styles.scss";

const computePayload = ({
    display,
    filters,
    resetFilter,
}: {
    display: ToggleSwitchOption;
    filters: {
        page: number;
        query?: string;
        status?: TaskStatus[];
        toSign?: boolean;
    };
    resetFilter?: boolean;
}): { filters?: FetchTasksFilters } => {
    return display === firstOptionDisplay
        ? {
              filters: {
                  ...(resetFilter ? {} : filters),
                  page: -1,
                  status:
                      filters.status && !resetFilter
                          ? filters.status
                          : ["pending", "draft", "sending", "to-validate", "to-retry"],
              },
          }
        : {
              filters: {
                  ...(resetFilter ? { page: 1 } : filters),
                  status: filters.status ?? [
                      "cancelled",
                      "complete",
                      "pending",
                      "refund-failed",
                      "refund-pending",
                      "sending",
                      "refunded",
                      "to-retry",
                      "waiting",
                  ],
              },
          };
};

type Props = {
    display: ToggleSwitchOption;
};

export function TasksFilters({ display }: Readonly<Props>) {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const { hash } = useLocation();

    const [closeSelect, setCloseSelect] = useState(false);

    const query = useAppSelector((state) => getTaskQuery(state));
    const toSignFilter = useAppSelector((state) => getTaskToSignFilter(state));
    const walletReferenceFilter = useAppSelector((state) => getTaskWalletReferenceFilter(state));
    const walletOptions = useAppSelector((state) => getWalletsAsSelectOptions(state));
    const currentPage = useAppSelector((state) => getTasksCurrentPage(state));

    const debouncedQuery = useDebounce(query, 700);

    const filters = useMemo(
        () => ({
            page: currentPage,
            query: debouncedQuery === "" ? undefined : debouncedQuery,
            status: hash ? [hash.replace("#", "") as TaskStatus] : undefined,
            toSign: toSignFilter,
            ...(walletReferenceFilter ? { wallets: walletReferenceFilter.map((wallet) => wallet.id) } : {}),
        }),
        [debouncedQuery, currentPage, hash, toSignFilter, walletReferenceFilter]
    );

    useEffect(() => {
        if (closeSelect) {
            setCloseSelect(false);
        }
    }, [closeSelect]);

    useEffect(() => {
        dispatch(fetchWalletsAuthUser());
    }, []);

    useEffect(() => {
        return () => {
            dispatch(resetTaskFilters());
        };
    }, [dispatch]);

    useEffect(() => {
        dispatch(fetchTasks(computePayload({ display, filters })));
    }, [dispatch, debouncedQuery, currentPage, display]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleSetToSign = useCallback(() => {
        dispatch(switchTaskToSignFilter());
    }, [dispatch]);

    const handleSetQueryFilter = useCallback(
        (newQuery: string) => {
            dispatch(setTaskWalletQuery({ query: newQuery }));
        },
        [dispatch]
    );

    const handleValidateFilters = () => {
        setCloseSelect(true);
        dispatch(fetchTasks(computePayload({ display, filters })));
    };

    const handleResetAllFilters = useCallback(() => {
        dispatch(resetTaskFilters());
        setCloseSelect(true);
        dispatch(fetchTasks(computePayload({ display, filters, resetFilter: true })));
    }, [dispatch, display, filters]);

    const handleResetFilter = useCallback(
        (filter: "toSign" | "walletReferences") => () => {
            dispatch(resetTaskFilter({ filter }));
            const { [filter === "walletReferences" ? "wallets" : filter]: filterToRemove, ...updatedFilters } = filters;
            setCloseSelect(true);
            dispatch(fetchTasks(computePayload({ display, filters: updatedFilters })));
        },
        [dispatch, display, filters]
    );

    const handleSetWalletReferenceFilter = (value: SelectOption[]) => {
        dispatch(setTaskWalletReferenceFilter({ walletReferences: value }));
    };

    const renderChip = () => {
        return (
            <div className='chip-container'>
                {walletReferenceFilter && (
                    <Chip
                        handleDelete={handleResetFilter("walletReferences")}
                        className='fw-lighter'
                        message={t("Wallets.wallets")}
                    />
                )}
                {toSignFilter && (
                    <div className='history-filters-chip-container'>
                        <div className='mr-1'>
                            <Chip
                                handleDelete={handleResetFilter("toSign")}
                                className='fw-lighter'
                                message={t("Tasks.to-sign")}
                            />
                        </div>
                    </div>
                )}
                {!walletReferenceFilter && !toSignFilter && t("CommonUse.filters")}
            </div>
        );
    };

    return (
        <div className='filter-task-container'>
            <Input
                placeholder={t("CommonUse.search")}
                inputIcon='magnifying-glass'
                onChange={handleSetQueryFilter}
                value={query}
                className='search-input ml-2'
                inputClassname='background-white'
            />
            <div className='filters-select ml-2'>
                <Select
                    forceClose={closeSelect}
                    color='white'
                    content={{
                        header: {
                            component: renderChip(),
                            icon: "adjustments-vertical",
                            disabled: false,
                        },
                        dropDownContent: {
                            header: {
                                title: <Typography message={t("CommonUse.filters")} />,
                                label: (
                                    <Button
                                        variant='ghost'
                                        color='lilas'
                                        onClick={handleResetAllFilters}
                                        size='sm'
                                        label={t("HistoryWalletFilters.supprimer-les-filtres")}
                                    />
                                ),
                            },
                            body: (
                                <div className='body-content'>
                                    <SelectMultipleOptions
                                        scrollable
                                        showSelectedItems={false}
                                        name={`${t("Tasks.filter-by-wallet")} ${
                                            walletReferenceFilter?.length
                                                ? "(" + walletReferenceFilter?.length + ")"
                                                : ""
                                        }`}
                                        selected={walletReferenceFilter}
                                        items={walletOptions}
                                        onSelectionChange={handleSetWalletReferenceFilter}
                                        icon='clock'
                                    />
                                    <Button
                                        disabled={!!hash}
                                        className='w-100'
                                        icon={"user-circle"}
                                        label={t("Tasks.to-sign")}
                                        onClick={handleSetToSign}
                                        variant={toSignFilter ? "primary" : "outline"}
                                    />
                                </div>
                            ),
                            footer: (
                                <div className='footer-content'>
                                    <Button
                                        className='background-neutral-200 footer-btn'
                                        variant='tertiary'
                                        onClick={handleResetAllFilters}
                                        label={t("CommonUse.cancel")}
                                    />
                                    <Button
                                        className='footer-btn'
                                        variant='primary'
                                        onClick={handleValidateFilters}
                                        label={t("CommonUse.validate")}
                                    />
                                </div>
                            ),
                        },
                    }}
                />
            </div>
        </div>
    );
}
