import { AdminActivityLogsFilters } from "./Filters";
import { AdminActivityLogsItem } from "./Item";
import classNames from "classnames";
import { AdminActivityLog } from "src/services/admin/activityLogs/types";

import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { useAdminActivityLogs, useFetchAdminActivityLogs } from "src/modules/admin/activityLogs/hooks";
import {
    getAdminActivityLogsIsLoading,
    getAdminActivityLogsLastEvaluatedKey,
} from "src/modules/admin/activityLogs/selectors";
import { clearAdminActivityLogs, resetAdminActivityLogsFilters } from "src/modules/admin/activityLogs/slice";
import { getAdminWallets } from "src/modules/admin/adminWallets/selectors";
import { fetchAdminWallets } from "src/modules/admin/adminWallets/thunkActions";
import { SubscriptionRestrictions, useSubscriptionRestrictions } from "src/shared/hooks/useSubscriptionRestrictions";
import { useAppDispatch, useAppSelector } from "src/store";

import { Alert } from "src/shared/atoms/Alert/Alert";
import { Empty } from "src/shared/components/Empty/Empty";
import { PageHeader } from "src/shared/components/PageHeader/PageHeader";
import { ScrollableList } from "src/shared/components/ScrollableList/ScrollableList";

import "./styles.scss";

const emptyListImg = require("src/shared/images/empty/empty-user.svg").default;

export function AdminAccountActivityPanel() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const activityLogs = useAdminActivityLogs() ?? [];
    const isLoading = useAppSelector((state) => getAdminActivityLogsIsLoading(state));
    const lastEvaluatedKey = useAppSelector((state) => getAdminActivityLogsLastEvaluatedKey(state));
    const handleFetchActivityLogs = useFetchAdminActivityLogs();

    const { loading, totalCount } = useAppSelector(getAdminWallets);
    const [fetched, setFetched] = useState(false);
    const isActivityLogsRestricted = useSubscriptionRestrictions(SubscriptionRestrictions.FEATURE_ACTIVITY_LOG);

    const [computedActivityLogs, setComputedActivityLogs] = useState(activityLogs);

    useEffect(() => {
        if (activityLogs.length > 0) {
            const duplicatedLogs = activityLogs.flatMap((log: AdminActivityLog) => {
                if (Array.isArray(log.details)) {
                    return log.details.map((detail: AdminActivityLog["details"]) => ({
                        ...log,
                        details: detail,
                    }));
                }
                return log;
            });
            setComputedActivityLogs(duplicatedLogs);
        }
    }, [activityLogs]);

    useEffect(() => {
        if (!loading && totalCount === 0 && !fetched) {
            dispatch(fetchAdminWallets({}));
            setFetched(true);
        }
    }, [dispatch, loading, totalCount, fetched]);

    useEffect(() => {
        return () => {
            dispatch(clearAdminActivityLogs());
            dispatch(resetAdminActivityLogsFilters());
        };
    }, [dispatch]);

    const handleOnScroll = () => {
        if (lastEvaluatedKey && !isLoading) {
            handleFetchActivityLogs();
        }
    };

    return (
        <div className={classNames("activity-log-container", { blur: isActivityLogsRestricted })}>
            <PageHeader title={t<string>("Cards.my-cards")}>
                <div className='filter-container'>
                    <AdminActivityLogsFilters />
                </div>
            </PageHeader>
            {isActivityLogsRestricted && (
                <Alert
                    icon='lock'
                    topBottom
                    message={t<string>("AdminActivity.restricted-feature")}
                    color='warning'
                    classname='mb-3'
                />
            )}
            <ScrollableList
                variant='secondary'
                isLoading={isLoading}
                keyName='activityLog'
                lastPage={false}
                handleOnScroll={handleOnScroll}
                items={computedActivityLogs.map((item) => (
                    <AdminActivityLogsItem key={`activity-log-${item.uuid}`} item={item} />
                ))}
            />
            {!isLoading && computedActivityLogs.length === 0 && (
                <div className='empty-container'>
                    <Empty
                        image={emptyListImg}
                        title={t("AdminActivity.no-activity-log")}
                        description={t<string>("AdminActivity.no-activity-log-desc")}
                    />
                </div>
            )}
        </div>
    );
}
