import { BeneficiaryDrawer } from "../Beneficiary/Drawer/Container";
import { TransactionServiceImage } from "../History/Info/ServiceImage";
import { WalletSelectionDrawer } from "../WalletSelectionDrawer/Drawer";
import { TransfertDetails } from "./Details/Details";
import { SuccessModalContent } from "./SuccessModal/SuccessModal";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { CreateTransactionParams } from "src/services/transactions/operations";
import { TransactionSlug } from "src/services/transactions/types";
import { requestWalletsBeneficiaries } from "src/services/wallets/operations";
import { WalletApi, WalletBeneficiary } from "src/services/wallets/types";
import { PasswordValidationModal } from "src/shared/common/Password/Modal";
import { operationTypes } from "src/shared/const/transactions";
import BeneficiaryImage from "src/shared/images/transfer-bank-disposal-illutstraions/select_beneficiary.png";
import { LoanModel } from "src/shared/models/Loan";
import { TransactionNotify } from "src/shared/models/Task";
import { CompanyStatus } from "src/shared/models/UserCompany";
import { formatMoneyToString } from "src/shared/utils/formatMoney";
import { removeExtraSpaces } from "src/shared/utils/removeExtraSpaces";
import { v4 as uuidv4 } from "uuid";

import { useEffect, useMemo, useState } from "react";

import { useTranslation } from "react-i18next";

import { getAuthUser, getAuthUserExpensePolicyByWallet } from "src/modules/auth/selectors";
import { fetchAuthUserExpensePolicies } from "src/modules/auth/thunkActions";
import { getLoans } from "src/modules/loans/selectors";
import { usePopulateRapidTransfertState } from "src/modules/transactions/hooks";
import { getTransactionsState } from "src/modules/transactions/selectors";
import { makeTransaction } from "src/modules/transactions/thunkActions";
import { getSendWalletLoading, getSendWallets, getWallet } from "src/modules/wallets/selectors";
import { fetchSendWalletsAuthUser } from "src/modules/wallets/thunkActions";
import { useAppDispatch, useAppSelector } from "src/store";

import { Button } from "src/shared/atoms/Buttons/Button";
import { DrawerInput } from "src/shared/atoms/Inputs/Drawer/Input";
import { Input } from "src/shared/atoms/Inputs/Input";
import { Typography } from "src/shared/atoms/Typography/Typography";
import { StepAccordion } from "src/shared/components/Accordion/StepAccordion/StepAccordion";
import { BlockedAccountAlert } from "src/shared/components/BlockedAccountAlert/BlockedAccountAlert";
import { KybAlert } from "src/shared/components/KybAlert/KybAlert";
import { PageHeader } from "src/shared/components/PageHeader/PageHeader";

import "./styles.scss";

export enum TRANSFERT_STEPS {
    DEBIT_SELECT,
    CREDIT_SELECT,
    DETAILS,
}

type LocationState = {
    preselectedWalletId?: string;
};

export const TransfertPanel = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const [step, setStep] = useState(TRANSFERT_STEPS.DEBIT_SELECT);
    const [selectedDebitAccount, setSelectedDebitAccount] = useState<WalletApi | null>(null);
    const [beneficiary, setBeneficiary] = useState<WalletBeneficiary | null>(null);
    const [isDebitDrawerOpened, setIsDebitDrawerOpened] = useState(true);
    const [isCreditDrawerOpened, setIsCreditDrawerOpened] = useState(false);
    const [amount, setAmount] = useState(0);
    const [comment, setComment] = useState("");
    const tfaActive = useAppSelector((state) => getAuthUser(state)?.tfaActive);
    const { makeTransactionError } = useAppSelector((state) => getTransactionsState(state));
    const [transactionSuccess, setTransactionSuccess] = useState(false);
    const [openPasswordDialog, setOpenPasswordDialog] = useState(false);
    const { loanId } = useParams();
    const [isLoanRepayment, setIsLoanRepayment] = useState(false);
    const [loan, setLoan] = useState<LoanModel>();
    const [beneficiaryName, setBeneficiaryName] = useState("");
    const [createdTransaction, setCreatedTransaction] = useState<undefined | TransactionNotify>();
    const [smsMessage, setSmsMessage] = useState<string | undefined>(undefined);
    const [selectedFees, setSelectedFees] = useState(false);
    const [commentError, setCommentError] = useState(false);
    const [beneficiaryNameError, setBeneficiaryNameError] = useState(false);

    const wallets = useAppSelector((state) => getSendWallets(state));
    const walletsLoading = useAppSelector((state) => getSendWalletLoading(state));
    const wallet = useAppSelector((state) => getWallet(state, { walletId: selectedDebitAccount?.id }));

    const providedState = useLocation().state as LocationState;
    const isValidProvidedState = !!wallets.find((wallet) => wallet.id === Number(providedState?.preselectedWalletId));
    const authUser = useAppSelector((state) => getAuthUser(state));
    const isBlockedAccount = !!(authUser && authUser.Company?.status !== CompanyStatus.BLOCK_0);

    const loans = useAppSelector((state) => getLoans(state));

    usePopulateRapidTransfertState({
        setAmount,
        setComment,
        setBeneficiary,
        setSelectedDebitAccount,
        setStep,
    });

    const creditButtonDisabled =
        !beneficiary ||
        beneficiaryNameError ||
        ((!beneficiary.serviceSelected.isExternalFullnameVerified || beneficiary.typeSlug === "bank-transfer") &&
            beneficiaryName === "");

    const expensePolicy = useAppSelector((state) =>
        getAuthUserExpensePolicyByWallet(state, { walletId: selectedDebitAccount?.id })
    );

    const expenseWalletData = useMemo(
        () =>
            expensePolicy && selectedDebitAccount?.id
                ? expensePolicy.spentMoneyPerWallet.find((item) => item.walletId === selectedDebitAccount.id)
                : null,
        [expensePolicy, selectedDebitAccount]
    );

    const spentMoney = expenseWalletData?.spentMoney ?? 0;
    const remainingBalance = expensePolicy ? expensePolicy?.limitPerUnitOfTime - spentMoney : 0;

    const taskUuid = useMemo(() => {
        return uuidv4().toString();
    }, []);

    useEffect(() => {
        dispatch(fetchAuthUserExpensePolicies());
        dispatch(fetchSendWalletsAuthUser());
    }, [dispatch]);

    useEffect(() => {
        if (!selectedDebitAccount && providedState?.preselectedWalletId && wallets.length && isValidProvidedState) {
            setSelectedDebitAccount(
                wallets.find((wallet) => wallet.id === Number(providedState.preselectedWalletId)) ?? null
            );
            if (isDebitDrawerOpened) {
                handleOpenDebitDrawer();
            }
            setStep(TRANSFERT_STEPS.CREDIT_SELECT);
        }
    }, [providedState?.preselectedWalletId, walletsLoading, wallets]);

    useEffect(() => {
        if (!wallets.length && !walletsLoading) {
            navigate("/dashboard");
        }
    }, [navigate, wallets, wallets.length, walletsLoading]);

    useEffect(() => {
        if (selectedDebitAccount && loan) {
            requestWalletsBeneficiaries({
                walletId: selectedDebitAccount.id,
                query: loan.uuid,
                way: "out",
            }).then((beneficiaries) => {
                let beneficiary = beneficiaries[0];
                beneficiary.serviceSelected.externalFullname = beneficiary.serviceSelected.serviceTitle;
                setBeneficiary(beneficiary);
            });
        }
    }, [selectedDebitAccount, loan]);

    useEffect(() => {
        if (loanId) {
            setIsLoanRepayment(true);

            if (!beneficiary) {
                setLoan(loans.find((loan) => loan.id === parseInt(loanId, 10)));
            }
        }
    }, [loanId, beneficiary]);

    useEffect(() => {
        if (wallets.length === 1) {
            setSelectedDebitAccount(wallets[0]);
            setIsDebitDrawerOpened(false);
        }
    }, [wallets]);

    useEffect(() => {
        setCommentError(!/^[a-zA-Z0-9\s\.\-_]*$/.test(comment));
    }, [comment]);

    useEffect(() => {
        setBeneficiaryNameError(!/^[a-zA-Z0-9\s\.\-_]*$/.test(beneficiaryName));
    }, [beneficiaryName]);

    useEffect(() => {
        if (selectedDebitAccount) {
            setStep(TRANSFERT_STEPS.CREDIT_SELECT);
        }
    }, [selectedDebitAccount]);

    const onConfirm = () => {
        setOpenPasswordDialog(true);
    };

    const checkAccordionDebitOpening = () => {
        const isValidated = Boolean(selectedDebitAccount);
        if (isValidated) {
            setStep(TRANSFERT_STEPS.CREDIT_SELECT);
        }
    };

    const checkAccordionCreditOpening = () => {
        const isValidated = Boolean(beneficiary);
        if (beneficiaryName && beneficiary && !beneficiary.serviceSelected.externalFullname) {
            let updatedCreditAccountServiceSelected = {
                ...beneficiary.serviceSelected,
                externalFullname: removeExtraSpaces(beneficiaryName),
            };

            let updatedCreditAccount = {
                ...beneficiary,
                serviceSelected: { ...updatedCreditAccountServiceSelected },
            };

            setBeneficiary(updatedCreditAccount);
        }
        if (isValidated) {
            setStep(TRANSFERT_STEPS.DETAILS);
        }
    };

    useEffect(() => {
        const handleGlobalEnterPress = (event: any) => {
            if (event.key === "Enter") {
                switch (step) {
                    case TRANSFERT_STEPS.DEBIT_SELECT:
                        if (selectedDebitAccount) {
                            checkAccordionDebitOpening();
                        } else {
                            handleOpenDebitDrawer();
                        }
                        break;
                    case TRANSFERT_STEPS.CREDIT_SELECT:
                        if (beneficiaryNameError) {
                            return;
                        } else if (beneficiary && !creditButtonDisabled) {
                            checkAccordionCreditOpening();
                        } else {
                            handleOpenCreditDrawer();
                        }
                        break;
                    case TRANSFERT_STEPS.DETAILS:
                        if (amount && beneficiary && selectedDebitAccount && !commentError) {
                            onConfirm();
                        }
                        break;
                    default:
                        break;
                }
            }
        };

        document.addEventListener("keydown", handleGlobalEnterPress);

        return () => {
            document.removeEventListener("keydown", handleGlobalEnterPress);
        };
    }, [
        step,
        selectedDebitAccount,
        beneficiary,
        amount,
        creditButtonDisabled,
        onConfirm,
        checkAccordionDebitOpening,
        checkAccordionCreditOpening,
    ]);

    const handleSwitchOpenDialog = () => {
        setOpenPasswordDialog((oldOpenDialog) => {
            return !oldOpenDialog;
        });
    };

    const handleOpenDebitDrawer = () => {
        setIsDebitDrawerOpened(!isDebitDrawerOpened);
    };

    const handleOpenCreditDrawer = () => {
        if (!loanId) {
            setIsCreditDrawerOpened(!isCreditDrawerOpened);
        }
    };

    const handleSelectDebitAccount = (account: WalletApi) => {
        setSelectedDebitAccount(account);
        setIsDebitDrawerOpened(false);
    };

    const handleSelectCreditAccount = (account: WalletBeneficiary) => {
        setBeneficiary(account);
        setIsCreditDrawerOpened(false);
    };

    const handleCommentChange = (value: string) => {
        setComment(value);
    };

    const hanldeAmountChange = (amount: number) => {
        setAmount(amount);
    };

    const handleSetSmsTemplate = (smsTemplate: string | undefined) => {
        setSmsMessage(smsTemplate);
    };

    const handleConfirm = async ({ password, twoFactor }: { password: string; twoFactor: string | null }) => {
        await handleSubmit(password, twoFactor);
    };

    const taskSignUniqueId = useMemo(() => {
        return uuidv4().toString();
    }, []);

    const handleSubmit = async (password: string, twoFactor: string | null) => {
        if (!selectedDebitAccount || !beneficiary) {
            return;
        }

        const willCreateTask = wallet?.canInit && remainingBalance < amount && comment;

        const params: CreateTransactionParams = {
            walletId: selectedDebitAccount.id.toString(),
            typeSlug: beneficiary.typeSlug,
            way: "out",
            title: isLoanRepayment
                ? "Remboursement de prêt"
                : willCreateTask
                ? comment
                : beneficiary.serviceSelected.externalFullname ||
                  beneficiary.serviceSelected.externalReference ||
                  beneficiaryName ||
                  "",
            code: password,
            taskUuid,
            smsTemplate: smsMessage ? smsMessage : undefined,
            includeReceiverFees: selectedFees,
            items: [
                {
                    amount: amount,
                    externalReference: beneficiary.serviceSelected.externalReference,
                    externalFullname: beneficiaryName,
                    serviceSlug: beneficiary.serviceSelected.serviceSlug,
                    typeSlug: beneficiary.typeSlug,
                    tags: !isLoanRepayment ? beneficiary.tag ?? "" : "loan-repayment",
                    comment: comment,
                },
            ],
            ...(tfaActive ? { otp: twoFactor } : {}),
        };

        await dispatch(
            makeTransaction({
                params,
                attachmentParams: null,
                uniqueId: taskSignUniqueId,
            })
        )
            .unwrap()
            .then((res) => {
                if (res) {
                    setCreatedTransaction(res);
                }
                setTransactionSuccess(true);
            });
    };

    const handleOpenAccordion = (step: TRANSFERT_STEPS) => () => {
        setStep(step);
    };

    const handleSetBeneficiaryName = (value: string) => {
        setBeneficiaryName(value);
    };

    const handleRedirect = () => {
        if (selectedDebitAccount?.id) {
            if (!createdTransaction?.isSent) {
                navigate("/tasks", { state: { taskId: createdTransaction?.id } });
            } else {
                navigate("/dashboard", {
                    state: { walletId: selectedDebitAccount.id },
                });
            }
        }
    };

    useEffect(() => {
        handleSetBeneficiaryName(beneficiary?.serviceSelected.externalFullname || "");
    }, [beneficiary]);

    const handleSetSelectedFees = () => {
        setSelectedFees(!selectedFees);
    };

    return (
        <>
            <PageHeader title={t<string>("TransfertPanel.transfert-rapide")} />
            <div className='transfert-container'>
                <BlockedAccountAlert />
                <KybAlert />
                <StepAccordion
                    title={t("TransfertPanel.choisissez-un-compte-a-debiter")}
                    step={TRANSFERT_STEPS.DEBIT_SELECT}
                    subTitle={
                        step > TRANSFERT_STEPS.DEBIT_SELECT && selectedDebitAccount
                            ? selectedDebitAccount.label +
                              " - " +
                              formatMoneyToString({ amount: selectedDebitAccount.balance })
                            : undefined
                    }
                    currentStep={step}
                    onOpen={handleOpenAccordion(TRANSFERT_STEPS.DEBIT_SELECT)}
                    footer={
                        <Button
                            className='continue-button'
                            variant='primary'
                            label={t("CommonUse.continue")}
                            onClick={checkAccordionDebitOpening}
                            disabled={!selectedDebitAccount || isBlockedAccount}
                            testId='transfert-continue-button-debit'
                        />
                    }
                >
                    <DrawerInput
                        label={
                            !selectedDebitAccount
                                ? t<string>("TransfertPanel.choisissez-un-compte")
                                : selectedDebitAccount.label +
                                  " - " +
                                  formatMoneyToString({ amount: selectedDebitAccount.balance })
                        }
                        handleClickDrawerInput={handleOpenDebitDrawer}
                        icon='wallet'
                        testId='transfert-drawer-input-debit'
                        disabled={isBlockedAccount && walletsLoading}
                    />
                </StepAccordion>

                {!isLoanRepayment && (
                    <StepAccordion
                        title={t("TransfertPanel.choisissez-un-compte-beneficiaire")}
                        step={TRANSFERT_STEPS.CREDIT_SELECT}
                        subTitle={
                            step > TRANSFERT_STEPS.CREDIT_SELECT && beneficiary
                                ? beneficiaryName + " - " + beneficiary.serviceSelected.serviceTitle
                                : undefined
                        }
                        currentStep={step}
                        onOpen={handleOpenAccordion(TRANSFERT_STEPS.CREDIT_SELECT)}
                        footer={
                            <div className='buttons-spacing'>
                                <Button
                                    variant='tertiary'
                                    label={t("CommonUse.go-back")}
                                    onClick={() => setStep(TRANSFERT_STEPS.DEBIT_SELECT)}
                                />
                                <Button
                                    className='continue-button'
                                    variant='primary'
                                    disabled={creditButtonDisabled}
                                    label={t("CommonUse.continue")}
                                    onClick={checkAccordionCreditOpening}
                                    testId='transfert-continue-button-credit'
                                />
                            </div>
                        }
                    >
                        <DrawerInput
                            label={
                                !beneficiary
                                    ? t<string>("TransfertPanel.choisissez-un-beneficiaire")
                                    : beneficiary?.serviceSelected.externalFullname?.length
                                    ? beneficiary.serviceSelected.externalFullname
                                    : beneficiary.serviceSelected.externalReference
                            }
                            handleClickDrawerInput={handleOpenCreditDrawer}
                            icon='user-circle'
                            testId='transfert-drawer-input-credit'
                        />
                        <div className='divider my-4' />
                        {beneficiary && (
                            <div className='selected-credit-account-summary'>
                                <div className='selected-account-summary-item'>
                                    <Typography message={t("BankDisposal.account-holder")} className='fw-bold' />
                                    {!beneficiary?.serviceSelected.isExternalFullnameVerified ? (
                                        <Input
                                            placeholder={t("TransfertPanel.fill-beneficiary-name")}
                                            onChange={handleSetBeneficiaryName}
                                            value={beneficiaryName}
                                            className='search-input'
                                            variant={beneficiaryNameError ? "error" : undefined}
                                            underMessage={
                                                beneficiaryNameError ? t<string>("CommonUse.no-spec-char") : undefined
                                            }
                                        />
                                    ) : (
                                        <Typography message={beneficiary.serviceSelected.externalFullname} />
                                    )}
                                </div>
                                <div className='selected-account-summary-item'>
                                    <Typography message={t("CommonUse.reference")} className='fw-bold' />
                                    <Typography message={beneficiary.serviceSelected.externalReference} />
                                </div>
                                <div className='selected-account-summary-item'>
                                    <Typography message={t("TransfertPanel.tranfert-type")} className='fw-bold' />
                                    <Typography
                                        message={operationTypes[beneficiary.typeSlug as TransactionSlug].title}
                                    />
                                </div>
                                <div className='selected-account-summary-item'>
                                    <Typography message={t("TransfertPanel.transfert-way")} className='fw-bold' />
                                    <TransactionServiceImage serviceSlug={beneficiary.serviceSelected.serviceSlug} />
                                </div>
                            </div>
                        )}
                        {!beneficiary && (
                            <div className='beneficiary-illustration'>
                                <img
                                    className='beneficiary-illustration-image'
                                    src={BeneficiaryImage}
                                    alt='Select Beneficiary pic'
                                />
                                <Typography
                                    className='fw-bold'
                                    message={t("TransfertPanel.qui-est-le-destinataire-de-votre-transfert")}
                                />
                                <Typography
                                    message={t(
                                        "TransfertPanel.choisissez-parmi-votre-liste-de-destinataires-ou-entrez-un-nouveau-numero-de-telephone"
                                    )}
                                />
                            </div>
                        )}
                    </StepAccordion>
                )}

                <StepAccordion
                    title={t("TransfertPanel.renseignez-les-details-de-votre-transfert")}
                    step={!isLoanRepayment ? TRANSFERT_STEPS.DETAILS : 1}
                    currentStep={step}
                    onOpen={handleOpenAccordion(!isLoanRepayment ? TRANSFERT_STEPS.DETAILS : 1)}
                    footer={
                        <div className='buttons-spacing'>
                            <Button
                                variant='tertiary'
                                label={t("CommonUse.go-back")}
                                onClick={() => setStep(TRANSFERT_STEPS.CREDIT_SELECT)}
                            />
                            <Button
                                className='continue-button'
                                variant='primary'
                                label={t("CommonUse.continue")}
                                disabled={!amount || !beneficiary || !selectedDebitAccount || commentError}
                                onClick={onConfirm}
                                testId='transfert-continue-button-details'
                            />
                        </div>
                    }
                >
                    {beneficiary && selectedDebitAccount && (
                        <TransfertDetails
                            debitAccount={selectedDebitAccount}
                            beneficiary={beneficiary}
                            beneficiaryName={beneficiaryName}
                            amount={amount}
                            comment={comment}
                            setAmount={hanldeAmountChange}
                            setComment={handleCommentChange}
                            loanDetails={loan}
                            setSmsMessage={handleSetSmsTemplate}
                            selectedFees={selectedFees}
                            setSelectedFees={handleSetSelectedFees}
                            commentError={commentError}
                            isLoanRepayment={isLoanRepayment}
                        />
                    )}
                    {!beneficiary ||
                        (!selectedDebitAccount && (
                            <Typography
                                className='text-danger'
                                message={`${t("TransfertPanel.oups-il-y-a-eu-un-probleme-veuillez-selectionner-un")} ${
                                    beneficiary ? t("TransfertPanel.compte-debiteur") : t("Wallets.wallets")
                                }`}
                            />
                        ))}
                </StepAccordion>
            </div>
            <WalletSelectionDrawer
                handleSelectedWallet={handleSelectDebitAccount}
                isOpen={isDebitDrawerOpened}
                onClose={handleOpenDebitDrawer}
                walletType='SEND'
                disableZeroBalance
            />
            <BeneficiaryDrawer
                walletId={String(selectedDebitAccount?.id)}
                selectOnlyBeneficiary={expensePolicy?.isForBeneficiariesOnly}
                handleSelectBeneficiary={handleSelectCreditAccount}
                handleSetClose={handleOpenCreditDrawer}
                isOpen={isCreditDrawerOpened}
            />
            <PasswordValidationModal
                header={{
                    title: t("CommonUse.transfert-money"),
                    icon: "arrows-right-left",
                }}
                successComponent={
                    selectedDebitAccount ? (
                        <SuccessModalContent
                            createdTransaction={createdTransaction}
                            totalAmountDischarged={amount}
                            currentAccountBalance={selectedDebitAccount?.balance ?? 0}
                            walletId={selectedDebitAccount?.id || 0}
                        />
                    ) : (
                        <Typography
                            className='color-error fw-bold'
                            message={t(
                                "TransfertPanel.oups-il-y-a-eu-un-probleme-veuillez-reesayer-ou-contactez-le-support"
                            )}
                        />
                    )
                }
                asyncFromParent
                error={makeTransactionError}
                succeeded={transactionSuccess}
                open={openPasswordDialog}
                setOpen={handleSwitchOpenDialog}
                handleOnSubmit={handleConfirm}
                onSuccess={handleRedirect}
            />
        </>
    );
};
