import { cutLineBase64 } from "./assets/cutLineBase64";
import { dakarBankBase64 } from "./assets/dakarBankBase64";
import { ivoryCoastBankBase64 } from "./assets/ivoryCoastBankBase64";
import { julayaLogoBase64 } from "./assets/julayaLogoBase64";
import { WalletApi } from "src/services/wallets/types";
import { countryCodeBank } from "src/shared/const/countryPhonePrefixes";

import { TFunction } from "i18next";

const addSpaceIban = (iban: string, spacesIndexToAdd: number[]) => {
    let lastIndex = 0;

    const values = spacesIndexToAdd.map((index) => {
        const ret = iban.slice(lastIndex, index);
        lastIndex = index;
        return ret;
    });

    values.push(iban.slice(lastIndex, iban.length));

    return values.join(" ");
};

const countryBankImage: { [countryCode: string]: string } = {
    CI: ivoryCoastBankBase64,
    SN: dakarBankBase64,
    BN: "",
};

type GenerateIbanProps = {
    t: TFunction<"translation", string, "translation">;
    companyName: string;
    wallet: WalletApi;
};

const generateIbanItem = ({ t, wallet, companyName }: GenerateIbanProps) => {
    const iban = wallet.iban;

    const country = iban.slice(0, 2);
    const bankNumber = iban.slice(4, 9);
    const agenceNumber = iban.slice(9, 14);
    const accountNumber = iban.slice(14, 26);
    const ribNumber = iban.slice(26, 28);

    const bankData = countryCodeBank[country];

    return `
    <div style="display: flex; width: 100%; justify-content: space-between; margin-bottom:24px">
        <img style="width: 150px; max-height: 58px" src=${julayaLogoBase64} />
        <img style="width: 50px; height: 26px" src=${countryBankImage[country]} />
    </div>
    <div style="display: flex; justify-content: space-between; margin-bottom: 24px">
        <div style="font-size: 16px; font-weight: 700; line-height: 24px; letter-spacing: -0.32px" >${t(
            "TopUp.account-details"
        )}</div>
        <div style="color: #737373; font-size: 14px; font-weight: 700; line-height: 20px; letter-spacing: -0.28px" >${t(
            "Iban.iban-section-title"
        )}</div>
    </div>
    <div style="width: 547px; height: 1.5px; background: #E9E9E9;"/>
    <div style="padding-top:24px; display:flex; " >
        <div style="display:flex; width:50%; color: #737373; font-size: 12px; font-weight: 700; line-height: 20px; letter-spacing: -0.24px;">${t(
            "BankDisposal.account-holder"
        )}</div>
        <div style="display:flex; width:50%; color: #737373; font-size: 12px; font-weight: 700; line-height: 20px; letter-spacing: -0.24px;">${t(
            "Iban.currency"
        )}</div>
    </div>
    <div style="display:flex;">
        <div style="display:flex; width:50%; color: #292929; font-size: 12px; font-weight: 700; line-height: 20px; letter-spacing: -0.24px;">${companyName}</div>
        <div style="display:flex; width:50%; color: #292929; font-size: 12px; font-weight: 700; line-height: 20px; letter-spacing: -0.24px;">${
            bankData.currency
        }</div>
    </div>
    <div style="margin-top:16px; display:flex;" >
        <div style="display:flex; width:50%; color: #737373; font-size: 12px; font-weight: 700; line-height: 20px; letter-spacing: -0.24px;">${t(
            "TopUp.iban"
        )}</div>
        <div style="display:flex; width:50%; color: #737373; font-size: 12px; font-weight: 700; line-height: 20px; letter-spacing: -0.24px;">${t(
            "TopUp.bic"
        )}</div>
    </div>
    <div style="display:flex;">
        <div style="display:flex; width:50%; color: #292929; font-size: 12px; font-weight: 700; line-height: 20px; letter-spacing: -0.24px;">${addSpaceIban(
            iban,
            bankData.addSpacesToIban
        )}</div>
        <div style="display:flex; width:50%; color: #292929; font-size: 12px; font-weight: 700; line-height: 20px; letter-spacing: -0.24px;">${
            bankData.bic
        }</div>
    </div>
    <div style="display:flex; margin-top:16px">
        <div style="display:flex; width:25%; color: #737373; font-size: 12px; font-weight: 700; line-height: 20px; letter-spacing: -0.24px;">${t(
            "BankDisposal.bank-code"
        )}</div>
        <div style="display:flex; width:25%; color: #737373; font-size: 12px; font-weight: 700; line-height: 20px; letter-spacing: -0.24px;">${t(
            "Iban.agency-code"
        )}</div>
        <div style="display:flex; width:25%; color: #737373; font-size: 12px; font-weight: 700; line-height: 20px; letter-spacing: -0.24px;">${t(
            "Iban.wallet-number"
        )}</div>
        <div style="display:flex; width:25%; color: #737373; font-size: 12px; font-weight: 700; line-height: 20px; letter-spacing: -0.24px;">${t(
            "BankDisposal.rib-key"
        )}</div>
    </div>
    <div style="display:flex;">
        <div style="display:flex; width:25%; color: #292929; font-size: 12px; font-weight: 700; line-height: 20px; letter-spacing: -0.24px;">${bankNumber}</div>
        <div style="display:flex; width:25%; color: #292929; font-size: 12px; font-weight: 700; line-height: 20px; letter-spacing: -0.24px;">${agenceNumber}</div>
        <div style="display:flex; width:25%; color: #292929; font-size: 12px; font-weight: 700; line-height: 20px; letter-spacing: -0.24px;">${accountNumber}</div>
        <div style="display:flex; width:25%; color: #292929; font-size: 12px; font-weight: 700; line-height: 20px; letter-spacing: -0.24px;">${ribNumber}</div>
    </div>`;
};

export const generateIbanHtml = (props: GenerateIbanProps) => {
    const ibanGenerated = generateIbanItem(props);

    return `
    <body>
        <div style="display:flex; flex-direction: column; width:calc(595px - 48px); padding: 24px; font-family: Lato, sans-serif">
            <div>
                ${ibanGenerated}
                <img style="width: 547px; max-height: 24px; margin-top:16px; margin-bottom:24px" src=${cutLineBase64} />
                ${ibanGenerated}
            </div>
        </div>
    </body>
    `;
};
