import { browserName } from "react-device-detect";
import { REACT_APP_ENV } from "src/services/utils/const";

import { useEffect, useState } from "react";

import { getAuthUser } from "src/modules/auth/selectors";
import { useAuthUserSelectedCompany } from "src/modules/authCompanies/hooks";
import { getJulayaWallets } from "src/modules/wallets/selectors";
import { useAppSelector } from "src/store";

const chmln = require("@chamaeleonidae/chmln");

chmln.init("SdhqbFOfej9FJjPjtiGJStRxMF2S6aqc0HnwHxilHVjd4h-1PMW1T-EkWCdBuCBJrvSDeQ", {
    fastUrl: "https://fast.chameleon.io/",
});

type ChameleonPayload = {
    environment: string;
    email: string;
    nbWallets: number;
    username: string;
    created: string;
    role: string;
    country: string;
    browser: string;
    phone: string;
    prefix: string;
    dateOfBirth: string;
    company: {
        companyName: string | undefined;
        id: number | undefined;
        nbUsers: number | undefined;
        nbCollectors: number;
        created: string;
        kyc: null | string;
        plan: null | string;
    };
};

export function useChameleon() {
    const [initialized, setInitialized] = useState(false);

    const authUser = useAppSelector((state) => getAuthUser(state));
    const wallets = useAppSelector((state) => getJulayaWallets(state));
    const company = useAuthUserSelectedCompany();

    useEffect(() => {
        if (!initialized && authUser?.countryCode && company && wallets.length) {
            chmln.identify(String(authUser.id), {
                environment: REACT_APP_ENV,
                email: authUser.email,
                nbWallets: wallets.length,
                created: authUser.createdAt,
                role: authUser.role,
                phone: authUser.phone,
                username: `${authUser.firstname ? authUser.firstname : ""} ${
                    authUser.lastname ? authUser.lastname : ""
                }`.trim(),
                browser: browserName,
                country: authUser?.countryCode === "00225" ? "CIV" : authUser?.countryCode === "00221" ? "SEN" : "BEN",
                prefix: authUser.gender === 0 ? "Mr" : "Mme",
                dateOfBirth: authUser.dateOfBirth,
                company: {
                    companyName: company.name,
                    id: company.id,
                    nbUsers: company.nbrUsers,
                    nbCollectors: company.nbCollectors,
                    created: company.createdAt,
                    kyc: company.kyb,
                    plan: company?.plan?.slug ?? null,
                },

                billerReference: authUser?.Company?.reference,
            } as ChameleonPayload);
            setInitialized(true);
        }
    }, [authUser, company, wallets, initialized]);
}
