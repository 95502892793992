import { AdminActivityLog, AdminActivityLogSignataires } from "./types";

import { TFunction } from "i18next";

type ComputeSignatairesProps = {
    t: TFunction<"translation", string, "translation">;
    newUsers: AdminActivityLogSignataires;
    oldUsers: AdminActivityLogSignataires;
    userLogged: AdminActivityLog["user"];
    details: AdminActivityLog["details"];
};

const computeSuperSignatairesLogs = ({ t, newUsers, oldUsers, userLogged, details }: ComputeSignatairesProps) => {
    const newUserFiltered = newUsers.filter((user) => user.isSuperSignature);
    const oldUsersFiltered = oldUsers.filter((user) => user.isSuperSignature);
    const superSignatairesDeletedLogs = oldUsersFiltered.map((superSignataire) => {
        const isRemoved = !newUserFiltered.find((user) => user.id === superSignataire.id);
        return isRemoved
            ? t("AdminActivity.delete-super-signataire", {
                  userName: `${userLogged.firstname} ${userLogged.lastname}`,
                  newUserName: `${superSignataire.firstname ?? ""} ${superSignataire.lastname ?? ""}`,
                  walletName: details?.walletName ?? "",
              })
            : null;
    });

    const superSignatairesAddedLogs = newUserFiltered.map((superSignataire) => {
        const isAdded = !oldUsersFiltered.find((user) => user.id === superSignataire.id);
        return isAdded
            ? t("AdminActivity.add-super-signataire", {
                  userName: `${userLogged.firstname} ${userLogged.lastname}`,
                  newUserName: `${superSignataire.firstname ?? ""} ${superSignataire.lastname ?? ""}`,
                  walletName: details?.walletName ?? "",
              })
            : null;
    });
    return [...superSignatairesDeletedLogs, ...superSignatairesAddedLogs];
};

const computeSignataireLogsSwitchOrder = ({
    t,
    newUsers,
    oldUsers,
    userLogged,
    details,
    order,
}: ComputeSignatairesProps & { order: boolean }) => {
    const usersLogs = newUsers
        .filter((user) => !user.isSuperSignature)
        .sort((userA, userB) => userA.order - userB.order)
        .map((user) => {
            return t(order ? "AdminActivity.add-ordered-signataire" : "AdminActivity.add-unorder-signataire", {
                userName: `${userLogged.firstname} ${userLogged.lastname}`,
                newUserName: `${user.firstname ?? ""} ${user.lastname ?? ""}`,
                order: user.order,
                walletName: details?.walletName ?? "",
            });
        });

    return [
        t(
            order ? "AdminActivity.switch-order-to-ordered-wallet" : "AdminActivity.switch-order-to-unordered-wallet",

            {
                userName: `${userLogged.firstname ?? ""} ${userLogged.lastname ?? ""}`,
                walletName: details?.walletName ?? "",
            }
        ),
        ...computeSuperSignatairesLogs({ t, newUsers, oldUsers, userLogged, details }),
        ...usersLogs,
    ].filter((sentence) => sentence);
};

const computeSignataireLogsOrdered = ({ t, newUsers, oldUsers, userLogged, details }: ComputeSignatairesProps) => {
    const usersCreated = newUsers
        .filter((user) => !user.isSuperSignature)
        .sort((userA, userB) => userA.order - userB.order)
        .map((user) => {
            const isCreated = !oldUsers.some((oldUser) => user.id === oldUser.id);
            const oldUser = oldUsers.find((oldUser) => user.id === oldUser.id && user.order !== oldUser.order);

            if (isCreated || oldUser) {
                return t(isCreated ? "AdminActivity.add-ordered-signataire" : "AdminActivity.edit-ordered-signataire", {
                    userName: `${userLogged.firstname} ${userLogged.lastname}`,
                    newUserName: `${user.firstname ?? ""} ${user.lastname ?? ""}`,
                    order: user.order,
                    oldOrder: oldUser?.order,
                    walletName: details?.walletName ?? "",
                });
            }
            return null;
        });
    const usersDeleted = oldUsers
        .filter((user) => !user.isSuperSignature)
        .sort((userA, userB) => userA.order - userB.order)
        .map((user) => {
            const isDeleted = !newUsers.some((oldUser) => user.id === oldUser.id);

            if (isDeleted) {
                return t("AdminActivity.delete-ordered-signataire", {
                    userName: `${userLogged.firstname} ${userLogged.lastname}`,
                    newUserName: `${user.firstname ?? ""} ${user.lastname ?? ""}`,
                    order: user.order,
                    walletName: details?.walletName ?? "",
                });
            }
            return null;
        });
    return [
        ...computeSuperSignatairesLogs({ t, newUsers, oldUsers, userLogged, details }),
        ...usersCreated,
        ...usersDeleted,
    ].filter((sentence) => sentence);
};

const computeSignataireLogsUnordered = ({ t, newUsers, oldUsers, userLogged, details }: ComputeSignatairesProps) => {
    const usersCreated = newUsers
        .filter((user) => !user.isSuperSignature)
        .sort((userA, userB) => userA.order - userB.order)
        .map((user) => {
            const isCreated = !oldUsers.some((oldUser) => user.id === oldUser.id);

            if (isCreated) {
                return t("AdminActivity.add-unorder-signataire", {
                    userName: `${userLogged.firstname} ${userLogged.lastname}`,
                    newUserName: `${user.firstname ?? ""} ${user.lastname ?? ""}`,
                    walletName: details?.walletName ?? "",
                });
            }
            return null;
        });
    const usersDeleted = oldUsers
        .filter((user) => !user.isSuperSignature)
        .sort((userA, userB) => userA.order - userB.order)
        .map((user) => {
            const isDeleted = !newUsers.some((oldUser) => user.id === oldUser.id);

            if (isDeleted) {
                return t("AdminActivity.delete-unordered-signataire", {
                    userName: `${userLogged.firstname} ${userLogged.lastname}`,
                    newUserName: `${user.firstname ?? ""} ${user.lastname ?? ""}`,
                    walletName: details?.walletName ?? "",
                });
            }
            return null;
        });
    return [
        ...computeSuperSignatairesLogs({ t, newUsers, oldUsers, userLogged, details }),
        ...usersCreated,
        ...usersDeleted,
    ].filter((sentence) => sentence);
};

export const computeSignataireLogs = (
    t: TFunction<"translation", string, "translation">,
    userLogged: AdminActivityLog["user"],
    details: AdminActivityLog["details"],
    body: AdminActivityLog["body"]
) => {
    const newUsers = details?.newUserWallets;
    const oldUsers = details?.oldUserWallets;

    if (newUsers && oldUsers) {
        if (
            typeof details?.oldIsOrderedSignatures === "boolean" &&
            typeof body?.isOrderedSignatures === "boolean" &&
            details.oldIsOrderedSignatures !== body.isOrderedSignatures
        ) {
            return computeSignataireLogsSwitchOrder({
                t,
                newUsers,
                oldUsers,
                details,
                userLogged,
                order: body.isOrderedSignatures,
            });
        }
        if (body?.isOrderedSignatures) {
            return computeSignataireLogsOrdered({ t, newUsers, oldUsers, userLogged, details });
        }
        return computeSignataireLogsUnordered({ t, newUsers, oldUsers, userLogged, details });
    }

    return null;
};

export const computeBillersChangeLogs = (
    t: TFunction<"translation", string, "translation">,
    details: AdminActivityLog["details"],
    userLogged: AdminActivityLog["user"]
) => {
    if (details?.newValues) {
        const logs: string[] = [];

        const oldValues = (details.oldValues as Record<string, any>) ?? {};
        const newValues = details.newValues as Record<string, any>;

        const topLevelFields = ["description", "sector", "isPublic"];
        topLevelFields.forEach((field) => {
            const oldValue = oldValues[field];
            const newValue = newValues[field];

            if (newValue !== undefined && oldValue !== newValue) {
                if (field === "isPublic") {
                    logs.push(
                        t(`AdminActivity.edit-biller-${field}`, {
                            userName: `${userLogged.firstname} ${userLogged.lastname}`,
                            oldValue: oldValue ? t("AdminActivity.public") : t("AdminActivity.private"),
                            newValue: newValue ? t("AdminActivity.public") : t("AdminActivity.private"),
                        })
                    );
                } else {
                    logs.push(
                        t(`AdminActivity.edit-biller-${field}`, {
                            userName: `${userLogged.firstname} ${userLogged.lastname}`,
                            oldValue: oldValue || t("AdminActivity.undefined"),
                            newValue: newValue || t("AdminActivity.undefined"),
                        })
                    );
                }
            }
        });

        const billerOldFields = oldValues?.billerFields ?? {};
        const billerNewFields = newValues?.billerFields ?? {};
        const fieldsToCheck = ["invoiceReference", "payerReference"];

        fieldsToCheck.forEach((fieldKey) => {
            const oldField = billerOldFields[fieldKey] ?? {};
            const newField = billerNewFields[fieldKey] ?? {};

            if (newField.patern !== undefined && oldField.patern !== newField.patern) {
                logs.push(
                    t(`AdminActivity.edit-biller-${fieldKey}-patern`, {
                        userName: `${userLogged.firstname} ${userLogged.lastname}`,
                        refLabel: oldField.label,
                    })
                );
            }
            if (newField.label !== undefined && oldField.label !== newField.label) {
                logs.push(
                    t(`AdminActivity.edit-biller-${fieldKey}-label`, {
                        userName: `${userLogged.firstname} ${userLogged.lastname}`,
                        oldValue: oldField.label || t("AdminActivity.undefined"),
                        newValue: newField.label || t("AdminActivity.undefined"),
                    })
                );
            }

            if (newField.required !== undefined && oldField.required !== newField.required) {
                logs.push(
                    t(`AdminActivity.edit-biller-${fieldKey}-required`, {
                        userName: `${userLogged.firstname} ${userLogged.lastname}`,
                        oldValue: oldField.required ? t("AdminActivity.required") : t("AdminActivity.not-required"),
                        newValue: newField.required ? t("AdminActivity.required") : t("AdminActivity.not-required"),
                        refLabel: oldField.label,
                    })
                );
            }

            if (newField.display !== undefined && oldField.display !== newField.display) {
                logs.push(
                    t(`AdminActivity.edit-biller-${fieldKey}-display`, {
                        userName: `${userLogged.firstname} ${userLogged.lastname}`,
                        oldValue: oldField.display ? t("AdminActivity.displayed") : t("AdminActivity.not-displayed"),
                        newValue: newField.display ? t("AdminActivity.displayed") : t("AdminActivity.not-displayed"),
                        refLabel: oldField.label,
                    })
                );
            }
        });

        const uniqueLogs = Array.from(new Set(logs));
        return uniqueLogs;
    }

    return null;
};
