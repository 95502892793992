import { LoanDetailsDrawerBody } from "../DetailsDrawer/Body";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { TransactionApi } from "src/services/transactions/types";
import { LoanModel } from "src/shared/models/Loan";
import { CompanyRole } from "src/shared/models/UserCompany";
import { formatMoneyToString } from "src/shared/utils/formatMoney";

import { useState } from "react";

import { useTranslation } from "react-i18next";

import { useIsUserAuthorized } from "src/shared/hooks/useIsUserAuthorized";

import { Avatar } from "src/shared/atoms/Avatar/Avatar";
import { Button } from "src/shared/atoms/Buttons/Button";
import { Drawer } from "src/shared/atoms/Drawer/Drawer";
import { Icon } from "src/shared/atoms/Icons/Icon";
import { ToolTip } from "src/shared/atoms/Tooltip/Tooltip";
import { Typography } from "src/shared/atoms/Typography/Typography";
import { ArcGauge } from "src/shared/components/ArcGauge/ArcGauge";

import "./styles.scss";

type LoanCurrentProps = {
    currentLoan: LoanModel;
    historyTransactions: TransactionApi[];
};

export const LoanCurrent = ({ currentLoan, historyTransactions }: LoanCurrentProps) => {
    const [isDrawerOpened, setIsDrawerOpened] = useState(false);
    const navigate = useNavigate();

    const currentDate = moment();
    const createdAtDate = moment(currentLoan.createdAt);
    const diffInDays = currentDate.diff(createdAtDate, "days");
    const { t } = useTranslation();

    const handleOpenDrawer = () => {
        setIsDrawerOpened(!isDrawerOpened);
    };

    const isUserUnauthorized = useIsUserAuthorized({ allowedRoles: [CompanyRole.ADMIN_ONLY] });

    const calculateDisbursementDate = (createdAt: Date, disbursedDate: Date) => {
        const diffInDays = moment(disbursedDate).diff(moment(createdAt), "days");

        const duration = moment.duration(diffInDays, "days");
        const years = duration.years();
        const months = duration.months();
        const days = duration.days();

        let result = "";

        if (years > 0) {
            result += `${years} ${t("CommonUse.year")}${years > 1 ? "s" : ""} `;
        }

        if (months > 0) {
            result += `${months} ${t("CommonUse.month")} `;
        }

        if (days > 0) {
            result += `${days} ${t("CommonUse.day")}${days > 1 ? "s" : ""}`;
        }

        if (result === "") {
            return t("CommonUse.first-day");
        }

        return result;
    };

    const navigateToTransfert = () => {
        navigate(`/transfert/loan/${currentLoan.id}`);
    };

    return (
        <div className='loan-current-container'>
            <div className='loan-current-header'>
                <div className='loan-current-header-left-part'>
                    <Avatar icon='bills' />
                    <div className='loan-current-title'>
                        <Typography message={t("Loans.ongoing-credit")} className='fw-bold' />
                    </div>
                </div>
                <div className='loan-current-buttons'>
                    <Button
                        variant='secondary'
                        color='primary'
                        label={t("CommonUse.details")}
                        icon='arrow-top-right-on-square'
                        position='right'
                        onClick={handleOpenDrawer}
                    />
                    <Button
                        variant='primary'
                        color='primary'
                        label={t("Transactions.pay")}
                        icon='arrows-right-left'
                        position='right'
                        disabled={isUserUnauthorized}
                        onClick={navigateToTransfert}
                        className='payment-button'
                    />
                </div>
            </div>
            <div className='loan-current-content'>
                <div className='loan-gauge-section'>
                    <ArcGauge
                        value={diffInDays}
                        rangeValues={[20, 30, 60]}
                        inactiveGray={false}
                        dashedLine={true}
                        message={
                            <div className='loan-gauge-text'>
                                <Typography
                                    message={calculateDisbursementDate(currentLoan.createdAt, moment().toDate())}
                                    className='fw-bold'
                                />
                                <Typography message={t("Loans.time-since-start")} size='xs' />
                            </div>
                        }
                    />
                </div>
                <div className='loan-rest-to-pay-section'>
                    <Typography message={t("Loans.left-to-pay")} />
                    <Typography
                        variant='h5'
                        className='fw-bold mt-0'
                        message={`${formatMoneyToString({
                            amount: currentLoan.amountToRepay - currentLoan.amountRepaid,
                        })}`}
                    />
                    <div className='loan-rest-to-pay-interests'>
                        <Icon name='bills' color='lilas-900' />
                        <Typography
                            message={`${formatMoneyToString({ amount: currentLoan.amountToRepay })} ${t(
                                "Loans.initial-amount"
                            )}`}
                            className='color-lilas-900'
                        />
                    </div>
                </div>
                <div className='loan-additional-fees-section'>
                    <Typography message={t("Loans.account-changes")} />
                    <div className='loan-additional-fees-percentile'>
                        <Typography variant='h5' className='fw-bold mt-0' message={`${1}%`} />
                        <div className='information-tooltip-container'>
                            <ToolTip
                                content={<Typography message={t("Loans.extra-fees-description")} />}
                                children={<Icon name='information-circle' className='color-neutral' />}
                                activation={true}
                                position='bottom'
                            />
                        </div>
                    </div>
                    <div className='loan-rest-to-pay-interests'>
                        <Icon name='receipt-percent' color='lilas-900' />
                        <Typography message={t("Loans.extra-fees")} className='color-lilas-900' />
                    </div>
                </div>
            </div>

            <Drawer
                isOpen={isDrawerOpened}
                onClose={handleOpenDrawer}
                header={{
                    title: t("Loans.details"),
                    image: "bills",
                }}
                body={<LoanDetailsDrawerBody currentLoan={currentLoan} historyTransactions={historyTransactions} />}
            />
        </div>
    );
};
