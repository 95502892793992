import { CompanyRole } from "src/shared/models/UserCompany";
import { withAuthorization } from "src/shared/utils/withAuthorization";

import { TransfertPanel } from "src/components/Transfert/Panel";

const TransfertPageUnauthorized = () => {
    return <TransfertPanel />;
};

export const TransfertPage = withAuthorization({
    allowedRoles: [CompanyRole.ADMIN, CompanyRole.ADMIN_ONLY, CompanyRole.USER],
})(TransfertPageUnauthorized);
