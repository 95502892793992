import { BillingGetPaidDrawerSectionDetailsFields } from "./Fields";
import classNames from "classnames";
import {
    BillingBillerUpdatePayload,
    BillingEnterprise,
    BillingEnterpriseFieldData,
    BillingEnterpriseFieldNames,
} from "src/services/billing/enterprise/type";
import { billingCountryLabel } from "src/shared/const/countryPhonePrefixes";
import { sectorOptions } from "src/shared/const/sectors";

import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";

import { useTranslation } from "react-i18next";

import { getAuthUser, getAuthUserPreferenceSelectedCompany } from "src/modules/auth/selectors";
import { getBillingEnterprise } from "src/modules/billing/enterprise/selectors";
import { useFileSize } from "src/shared/hooks/useFileSize";
import { useAppSelector } from "src/store";

import { Button } from "src/shared/atoms/Buttons/Button";
import { Icon } from "src/shared/atoms/Icons/Icon";
import { Input } from "src/shared/atoms/Inputs/Input";
import { TextArea } from "src/shared/atoms/Inputs/TextArea";
import { Typography } from "src/shared/atoms/Typography/Typography";
import { IconButton } from "src/shared/components/IconButton/IconButton";
import { SelectOption } from "src/shared/components/SelectMultipleOptions/SelectMultipleOptions";
import { SimpleSelect } from "src/shared/components/SimpleSelect/SimpleSelect";

const defaultFields: BillingEnterprise["billerFields"] = {
    payerReference: {
        display: true,
        label: "Référence client",
        patern: "[a-zA-Z0-9-_.+*?]",
        required: false,
    },
    invoiceReference: {
        display: true,
        label: "Numéro de facture",
        patern: "[a-zA-Z0-9-_.+*?]",
        required: false,
    },
};

type Props = {
    edit: "details" | "invoiceReceipt" | null;
    setEdit: Dispatch<SetStateAction<"details" | "invoiceReceipt" | null>>;
    handleSetPayload: (props: BillingBillerUpdatePayload) => () => void;
};

export function BillingGetPaidDrawerSectionDetailsContainer({ edit, setEdit, handleSetPayload }: Readonly<Props>) {
    const { t } = useTranslation();

    const [details, setDetails] = useState("");
    const [sector, setSector] = useState<SelectOption | null>(null);
    const [fields, setFields] = useState<BillingEnterprise["billerFields"]>(defaultFields);

    const enterpriseData = useAppSelector((state) => getBillingEnterprise(state));
    const avatarSize = useFileSize(enterpriseData?.avatar);
    const company = useAppSelector((state) => getAuthUserPreferenceSelectedCompany(state));
    const authUser = useAppSelector((state) => getAuthUser(state));

    const handleSetEdit = (value: Props["edit"]) => () => {
        setEdit(value);
    };

    const handleChangeSector = (value: SelectOption) => {
        setSector(value);
    };

    const handleChangeDetails = (value: string) => {
        setDetails(value);
    };

    const handleSetField = (fieldType: BillingEnterpriseFieldNames, update: Partial<BillingEnterpriseFieldData>) => {
        setFields({ ...fields, [fieldType]: { ...fields[fieldType], ...update } });
    };

    useEffect(() => {
        if (enterpriseData) {
            const selectedSector = sectorOptions(t).find((option) => option.id === enterpriseData?.sector);
            setDetails(enterpriseData?.description ?? "");

            if (selectedSector) {
                setSector(selectedSector);
            }

            if (enterpriseData.billerFields) {
                setFields(enterpriseData.billerFields);
            }
        }
    }, [enterpriseData, t]);

    const editDisabled = edit !== null && edit !== "details";

    const payload = useMemo(
        () => ({
            description: details.trim().length > 0 ? details : null,
            ...(sector ? { sector: sector?.id } : {}),
            billerFields: fields,
        }),
        [details, sector, fields]
    );

    const errorOnFields = useMemo(() => {
        return Object.values(fields)
            .filter((field) => field.display)
            .some((field) => !field.patern.includes("a-zA-Z") && !field.patern.includes("0-9"));
    }, [fields]);

    return (
        <div
            className={classNames("drawer-content-section get-paid-drawer-section", {
                disabled: editDisabled,
            })}
        >
            <div className='header'>
                <Typography className='fw-bold' message={t("Bills.profile-informations")} />
                {edit === "details" ? (
                    <Button
                        disabled={editDisabled}
                        onClick={handleSetEdit(null)}
                        label={t("En cours de modification")}
                        variant='ghost'
                        color='warning'
                    />
                ) : (
                    <Button
                        disabled={editDisabled}
                        onClick={handleSetEdit("details")}
                        label={t("CommonUse.modify")}
                        variant='ghost'
                        color='lilas'
                    />
                )}
            </div>
            <div className='drawer-divider' />
            {edit === "details" ? (
                <div className='drawer-details-edit'>
                    <div className='biller-logo'>
                        <Typography
                            className='element color-neutral-900 fw-bold'
                            message={t("Bills.enterprise-avatar")}
                        />
                        <div className='d-flex justify-content-between align-items-center'>
                            <div className='d-flex align-items-center'>
                                {enterpriseData?.avatar ? (
                                    <>
                                        <div className='avatar'>
                                            <Icon size='lg' name='kiosk' color='lilas' />
                                        </div>
                                        <div className='d-flex flex-column ml-2'>
                                            <Typography
                                                className='fw-bold'
                                                message={enterpriseData.avatar.split("/").pop()}
                                            />
                                            <Typography className='color-neutral-500' message={`${avatarSize} MB`} />
                                        </div>
                                        <IconButton disabled iconProps={{ name: "thrash", color: "error" }} />
                                    </>
                                ) : (
                                    <>
                                        <div className='avatar'>
                                            <Icon size='lg' name='kiosk' color='lilas' />
                                        </div>
                                        <div className='d-flex flex-column ml-2'>
                                            <Typography className='fw-bold' message={t("Bills.no-enterprise-logo")} />
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                    <Input
                        disabled
                        placeholder=''
                        label={t<string>("Bills.enterprise-name")}
                        value={company?.name ?? ""}
                    />
                    <Input
                        disabled
                        placeholder=''
                        label={t<string>("Bills.biller-identifier")}
                        value={enterpriseData?.billerReference ?? ""}
                    />
                    <TextArea
                        onChange={handleChangeDetails}
                        maxLength={250}
                        label={t<string>("CommonUse.description")}
                        rows={5}
                        variant='neutral'
                        value={details}
                    />
                    <SimpleSelect
                        scrollable
                        useRadio
                        placeholder={t<string>("Bills.select-an-activity-sector")}
                        color='grey'
                        headerClassname='drawer-input-header'
                        label={t<string>("Bills.activity-sector")}
                        options={sectorOptions(t)}
                        selected={sector}
                        onSelectionChange={handleChangeSector}
                    />
                    <Input
                        placeholder=''
                        disabled
                        label={t<string>("Bills.country-of-activity")}
                        value={t<string>(billingCountryLabel[authUser?.countryCode ?? ""])}
                    />

                    {Object.entries(fields).map(([fieldName, fieldData]) => (
                        <BillingGetPaidDrawerSectionDetailsFields
                            key={`edit-${fieldName}`}
                            fieldType={fieldName as BillingEnterpriseFieldNames}
                            edit={edit}
                            field={fieldData}
                            setFields={handleSetField}
                        />
                    ))}
                    <div className='drawer-divider' />
                    <div className='d-flex justify-content-end'>
                        <Button
                            className='mr-2'
                            variant='tertiary'
                            label={t("CommonUse.cancel")}
                            onClick={handleSetEdit(null)}
                        />
                        <Button
                            label={t("CommonUse.modify")}
                            disabled={errorOnFields}
                            onClick={handleSetPayload(payload)}
                        />
                    </div>
                </div>
            ) : (
                <>
                    <div className='label-or-description'>
                        <Typography
                            className='element color-neutral-500 fw-bold'
                            message={t("Bills.enterprise-avatar")}
                        />
                        <div className='element'>
                            <div className='avatar'>
                                <Icon size='lg' name='kiosk' color='lilas' />
                            </div>
                        </div>
                    </div>
                    <div className='label-or-description'>
                        <Typography
                            className='element color-neutral-500 fw-bold'
                            message={t("Bills.enterprise-name")}
                        />
                        <Typography className='element fw-bold' message={company?.name ?? "-"} />
                    </div>
                    <div className='label-or-description'>
                        <Typography
                            className='element color-neutral-500 fw-bold'
                            message={t("Bills.biller-identifier")}
                        />
                        <Typography className='element fw-bold' message={enterpriseData?.billerReference ?? "-"} />
                    </div>
                    <div className='label-or-description'>
                        <Typography
                            className='element color-neutral-500 fw-bold'
                            message={t("CommonUse.description")}
                        />
                        <Typography className='element fw-bold' message={enterpriseData?.description ?? "-"} />
                    </div>
                    <div className='label-or-description'>
                        <Typography className='element color-neutral-500 fw-bold' message={t("Bills.sector")} />
                        <Typography
                            className='element fw-bold'
                            message={t(`Bills.sector-${enterpriseData?.sector ?? "other"}`)}
                        />
                    </div>
                    <div className='label-or-description'>
                        <Typography
                            className='element color-neutral-500 fw-bold'
                            message={t("Bills.country-of-activity")}
                        />
                        <Typography
                            className='element fw-bold'
                            message={t<string>(billingCountryLabel[authUser?.countryCode ?? ""])}
                        />
                    </div>

                    {Object.entries(enterpriseData?.billerFields ?? {}).map(([fieldName, fieldData]) => (
                        <BillingGetPaidDrawerSectionDetailsFields
                            key={`display-${fieldName}`}
                            fieldType={fieldName as BillingEnterpriseFieldNames}
                            edit={edit}
                            field={fieldData}
                        />
                    ))}
                </>
            )}
        </div>
    );
}
