import { CompanyRole } from "src/shared/models/UserCompany";
import { withAuthorization } from "src/shared/utils/withAuthorization";

import { BillingTopBillersPanel } from "src/components/Billing/PayBill/TopBillers/Panel";

export const BillingPayBillPageUnauthorized = () => {
    return <BillingTopBillersPanel />;
};

export const BillingPayBillPage = withAuthorization({
    allowedRoles: [CompanyRole.ADMIN, CompanyRole.USER],
})(BillingPayBillPageUnauthorized);
