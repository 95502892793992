import { AdminExpensePolicyCreateDrawerContainer } from "./CreateDrawer/Container";
import { AdminExpensePolicyEditDrawerContainer } from "./EditDrawer/Container";
import { AdminExpensePolicyFilters } from "./Filters";
import { ExpensePolicy } from "src/services/admin/expensePolicy/types";
import { formatMoneyToString } from "src/shared/utils/formatMoney";

import { useState } from "react";

import { useTranslation } from "react-i18next";

import { useAdminWalletsAsSelectOptions } from "src/modules/admin/adminWallets/hooks";
import { useAdminExpensePolicies } from "src/modules/admin/expensePolicies/hooks";
import {
    getAdminExpensePoliciesLoading,
    getAdminExpensePoliciesPage,
    getAdminExpensePoliciesPageSize,
    getAdminExpensePoliciesQuery,
    getAdminExpensePoliciesRemainingWalletOptions,
    getAdminExpensePoliciesTotalCount,
} from "src/modules/admin/expensePolicies/selectors";
import {
    setAdminExpensePolicyCreate,
    setAdminExpensePolicyPage,
    setAdminExpensePolicyPageSize,
} from "src/modules/admin/expensePolicies/slice";
import { computeExpensePolicyInForm } from "src/modules/admin/expensePolicies/utils";
import { useAdminAccountsAsSelectOptions } from "src/modules/admin/usersList/hooks";
import { SubscriptionRestrictions, useSubscriptionRestrictions } from "src/shared/hooks/useSubscriptionRestrictions";
import { useAppDispatch, useAppSelector } from "src/store";

import { Alert } from "src/shared/atoms/Alert/Alert";
import { Avatar } from "src/shared/atoms/Avatar/Avatar";
import { Button } from "src/shared/atoms/Buttons/Button";
import { Icon } from "src/shared/atoms/Icons/Icon";
import { Tag } from "src/shared/atoms/Tag/Tag";
import { ToolTip } from "src/shared/atoms/Tooltip/Tooltip";
import { Typography } from "src/shared/atoms/Typography/Typography";
import { Empty } from "src/shared/components/Empty/Empty";
import { SelectableTable, SelectableTableRow } from "src/shared/components/SelectableTable/SelectableTable";

import "./styles.scss";

const emptyAdminImage = require("src/shared/images/empty/welcome.png");
const emptyListImg = require("src/shared/images/empty/empty-user.svg").default;

export function AdminExpensePolicyPanel() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const [openEditDrawer, setopenEditDrawer] = useState(false);
    const [openCreateDrawer, setOpenCreateDrawer] = useState(false);

    const userOptions = useAdminAccountsAsSelectOptions(true);
    const walletOptions = useAdminWalletsAsSelectOptions({});
    const walletsOptionsLeft = useAppSelector((state) => getAdminExpensePoliciesRemainingWalletOptions(state));

    const noWalletsLeft = walletsOptionsLeft.length === 0;

    const expensePolicies = useAdminExpensePolicies();
    const loading = useAppSelector((state) => getAdminExpensePoliciesLoading(state));
    const currentPage = useAppSelector((state) => getAdminExpensePoliciesPage(state));
    const totalCount = useAppSelector((state) => getAdminExpensePoliciesTotalCount(state));
    const pageSize = useAppSelector((state) => getAdminExpensePoliciesPageSize(state));
    const query = useAppSelector((state) => getAdminExpensePoliciesQuery(state));

    const isExpensePolicyRestricted = useSubscriptionRestrictions(SubscriptionRestrictions.RESTRICTIONS_EXPENSE_POLICY);

    const handleOpenCreateDrawer = () => {
        setOpenCreateDrawer(true);
    };

    const handleSetCurrentPage = (newPage: number) => {
        dispatch(setAdminExpensePolicyPage({ value: newPage }));
    };

    const handleOpenEditDrawer = (expensePolicy: ExpensePolicy) => () => {
        dispatch(
            setAdminExpensePolicyCreate({
                value: computeExpensePolicyInForm({
                    t,
                    selectedExpensePolicy: expensePolicy,
                    expensePolicies,
                    walletOptions,
                    userOptions,
                }),
            })
        );
        setopenEditDrawer(true);
    };

    const handleSetPageSize = (newPageSize: number) => {
        dispatch(setAdminExpensePolicyPageSize({ value: newPageSize }));
    };

    const tableRows = Object.values(expensePolicies).map((expensePolicy) => {
        const { excludedWallets, excludedUsers, limitPerUnitOfTime, unitOfTime, isForBeneficiariesOnly, title } =
            expensePolicy;

        return [
            {
                content: <Avatar icon='adjustments-horizontal' />,
                onClick: handleOpenEditDrawer(expensePolicy),
                type: "image",
            },
            {
                content: (
                    <div className='d-flex flex-column'>
                        <Typography className='fw-bold' message={title} />
                        <Typography className='color-neutral-500' message={t("Expense-policy.pay-without-approve")} />
                    </div>
                ),
                onClick: handleOpenEditDrawer(expensePolicy),
            },
            {
                content: (
                    <div className='d-flex flex-column'>
                        <Typography className='fw-bold' message={t("Expense-policy.all-wallets")} />
                        {excludedWallets && excludedWallets.length > 0 && (
                            <div className='d-flex align-items-center'>
                                <Typography
                                    className='color-neutral-500 mr-1'
                                    message={t("Expense-policy.except-amount", {
                                        amount: excludedWallets.length,
                                    })}
                                />
                                <ToolTip
                                    position='bottom'
                                    activation
                                    content={
                                        <div className='expense-policy-tooltip'>
                                            {excludedWallets.map((option) => (
                                                <Typography
                                                    key={`tooltip-wallet-${option.id}`}
                                                    className='mt-1'
                                                    message={`${option.label} - ${option.reference}`}
                                                />
                                            ))}
                                        </div>
                                    }
                                >
                                    <Icon name='information-circle' color='neutral' />
                                </ToolTip>
                            </div>
                        )}
                    </div>
                ),
                onClick: handleOpenEditDrawer(expensePolicy),
            },
            {
                content: (
                    <div className='d-flex flex-column'>
                        <Typography className='fw-bold' message={t("Wallets.all-signatories")} />
                        {excludedUsers && excludedUsers.length > 0 && (
                            <div className='d-flex align-items-center'>
                                <Typography
                                    className='color-neutral-500 mr-1'
                                    message={t("Expense-policy.except-amount", {
                                        amount: excludedUsers.length,
                                    })}
                                />
                                <ToolTip
                                    position='bottom'
                                    activation
                                    content={
                                        <div className='expense-policy-tooltip'>
                                            {excludedUsers.map((option) => (
                                                <Typography
                                                    key={`tooltip-${option.id}`}
                                                    className='mt-1'
                                                    message={`${option.firstname} ${option.lastname}`.trim()}
                                                />
                                            ))}
                                        </div>
                                    }
                                >
                                    <Icon name='information-circle' color='neutral' />
                                </ToolTip>
                            </div>
                        )}
                    </div>
                ),
                onClick: handleOpenEditDrawer(expensePolicy),
            },
            {
                content: (
                    <div className='d-flex flex-column'>
                        <Typography
                            className='fw-bold color-error-500'
                            message={formatMoneyToString({ amount: limitPerUnitOfTime })}
                        />
                        <Typography
                            message={t("Expense-policy.per-unit-of-time", {
                                unitOfTime: t(`CommonUse.${unitOfTime}`),
                            })}
                        />
                    </div>
                ),
                onClick: handleOpenEditDrawer(expensePolicy),
            },
            {
                content: (
                    <Tag
                        type='accent'
                        size='lg'
                        label={t<string>(isForBeneficiariesOnly ? "Expense-policy.active" : "Expense-policy.disabled")}
                        icon={isForBeneficiariesOnly ? "check-circle" : "forbidden"}
                        color={isForBeneficiariesOnly ? "success" : "neutral"}
                    />
                ),
                onClick: handleOpenEditDrawer(expensePolicy),
            },
        ];
    });

    return (
        <>
            <div className='expence-policy-container'>
                {noWalletsLeft && walletOptions.length > 0 && (
                    <Alert
                        color='warning'
                        message={t("Expense-policy.all-wallet-assigned-expense-policy-title")}
                        subMessage={t<string>("Expense-policy.all-wallet-assigned-expense-policy-description")}
                    />
                )}
                <div className='filter-container'>
                    <AdminExpensePolicyFilters />
                    <ToolTip
                        position='bottom'
                        content={
                            <div className='tooltip-limited'>
                                <Typography message={t("Subscription.not-accessible-feature")} className='fw-bold' />
                                <Typography message={t("Subscription.unlock-unlimited-access")} />
                            </div>
                        }
                        activation={isExpensePolicyRestricted}
                    >
                        <Button
                            onClick={noWalletsLeft ? undefined : handleOpenCreateDrawer}
                            disabled={noWalletsLeft || isExpensePolicyRestricted}
                            variant='ghost'
                            icon='plus-circle'
                            color='lilas'
                            label={t("Expense-policy.add-expense-rule")}
                        />
                    </ToolTip>
                </div>
                <SelectableTable
                    isLoading={loading}
                    pagination={{
                        currentPage,
                        pageSize: pageSize,
                        totalCount,
                        onPageChange: handleSetCurrentPage,
                        onPageSizeChange: handleSetPageSize,
                    }}
                    header={[
                        "",
                        t("Expense-policy.name-and-description-of-rule"),
                        t("Wallets.wallets"),
                        t("Users.users"),
                        t("Expense-policy.max-amount"),
                        t("Expense-policy.only-beneficiary"),
                    ]}
                    empty={
                        <Empty
                            image={query ? emptyListImg : emptyAdminImage}
                            title={t(query ? "Expense-policy.no-expense-policy-rule" : "Table.welcome-to-julaya")}
                            description={t<string>(query ? "" : "Expense-policy.start-discover-julaya-platform")}
                            button={
                                query ? undefined : (
                                    <ToolTip
                                        position='right'
                                        content={
                                            <div className='tooltip-limited'>
                                                <Typography
                                                    message={t("Subscription.not-accessible-feature")}
                                                    className='fw-bold'
                                                />
                                                <Typography message={t("Subscription.unlock-unlimited-access")} />
                                            </div>
                                        }
                                        activation={isExpensePolicyRestricted}
                                    >
                                        <Button
                                            disabled={isExpensePolicyRestricted}
                                            onClick={handleOpenCreateDrawer}
                                            icon='plus-circle'
                                            label={t("Expense-policy.add-expense-rule")}
                                        />
                                    </ToolTip>
                                )
                            }
                        />
                    }
                    rows={tableRows as unknown as SelectableTableRow[][]}
                />
            </div>
            <AdminExpensePolicyEditDrawerContainer open={openEditDrawer} setOpen={setopenEditDrawer} />
            <AdminExpensePolicyCreateDrawerContainer open={openCreateDrawer} setOpen={setOpenCreateDrawer} />
        </>
    );
}
