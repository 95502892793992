import { UserDetailDrawerBody } from "./Body";
import { useLocation } from "react-router-dom";
import { UserFilterParams } from "src/services/admin/types";
import { PasswordValidationModal } from "src/shared/common/Password/Modal";
import { EditUserContext } from "src/shared/context/editUser";
import { CompanyRole } from "src/shared/models/UserCompany";

import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { getAdminDashboardInfoData } from "src/modules/admin/selectors";
import { fetchAdminDashboardInfoData } from "src/modules/admin/thunkActions";
import { getEditUser, getUserDetail } from "src/modules/admin/userDetail/selectors";
import {
    clearUserDetailData,
    fetchUserDetail,
    handleArchiveUser,
    handleChangeUserActiveStatus,
    handleUpdatedUser,
} from "src/modules/admin/userDetail/thunkActions";
import { fetchUsersList } from "src/modules/admin/usersList/thunkActions";
import { getAuthUser } from "src/modules/auth/selectors";
import { handleSendUserDocument } from "src/modules/documents/thunkActions";
import { useAppDispatch, useAppSelector } from "src/store";

import { Drawer } from "src/shared/atoms/Drawer/Drawer";

const successImage = require("src/shared/images/user/success.png");

type Props = {
    selectedUserId: number | null;
    onCloseDrawer: () => void;
    params?: UserFilterParams;
};

export function UserDetailDrawerContainer({ selectedUserId, onCloseDrawer, params }: Readonly<Props>) {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const location = useLocation();
    const adminDashboardInfo = useAppSelector((state) => getAdminDashboardInfoData(state));

    const [deactivateUserError, setDeactivateUserError] = useState<string | null>(null);
    const [archiveUserError, setArchiveUserError] = useState<string | null>(null);
    const [editingSection, setEditingSection] = useState<string | null>(null);
    const [canRoleEdit, setCanRoleEdit] = useState(false);
    const [payload, setPayload] = useState<any>();
    const [showSuccess, setShowSuccess] = useState(false);
    const [openPassword, setOpenPassword] = useState(false);
    const [isHidden, setIsHidden] = useState(false);

    const { userDetail } = useAppSelector(getUserDetail);
    const { error: editUserError, loading: editUserLoading } = useAppSelector(getEditUser);
    const authUser = useAppSelector(getAuthUser);

    const isOpenDrawer = Boolean(selectedUserId);

    useEffect(() => {
        if (selectedUserId) {
            dispatch(fetchUserDetail(selectedUserId));
        }
        // eslint-disable-next-line
    }, [selectedUserId]);

    useEffect(() => {
        //A vérifier, ne semble pas marcher
        if (!isOpenDrawer) {
            dispatch(clearUserDetailData());
        }
    }, [dispatch, isOpenDrawer]);

    useEffect(() => {
        if (userDetail && !!selectedUserId) {
            setCanRoleEdit(
                authUser?.role === "ADMIN" ||
                    (authUser?.role === CompanyRole.ADMIN_ONLY &&
                        ![CompanyRole.ADMIN, CompanyRole.ADMIN_ONLY].includes(userDetail.role))
            );
        } else {
            setCanRoleEdit(false);
        }

        if (!selectedUserId) {
            setPayload(null);
            setEditingSection("");
        }
        // eslint-disable-next-line
    }, [authUser, selectedUserId, userDetail]);

    useEffect(() => {
        if (editUserLoading === false && !editUserError && payload) {
            setShowSuccess(true);
            onCloseDrawer();
            if (location.pathname === "/admin" && !adminDashboardInfo) {
                dispatch(fetchAdminDashboardInfoData());
            }
        }
        // eslint-disable-next-line
    }, [editUserError, editUserLoading]);

    const handleSubmit = async (password: string, otp: string | null) => {
        if (!payload && userDetail) {
            return;
        }

        if (payload.archiveStatus) {
            setArchiveUserError(null);
            if (userDetail?.id) {
                await dispatch(
                    handleArchiveUser({
                        id: userDetail.id,
                        data: {
                            password,
                            otp,
                        },
                    })
                ).then((res: any) => {
                    if (res.error) {
                        setArchiveUserError(res.error.message);
                    } else {
                        onCloseDrawer();
                        if (params) {
                            dispatch(fetchUsersList(params));
                        }
                    }
                });
            }
        } else if (payload.activeStatus) {
            setDeactivateUserError(null);
            if (userDetail?.id) {
                await dispatch(
                    handleChangeUserActiveStatus({
                        id: [userDetail.id],
                        data: {
                            password,
                            otp,
                        },
                    })
                ).then((res: any) => {
                    if (res.error) {
                        setDeactivateUserError(res.error.message);
                    } else {
                        onCloseDrawer();
                        if (params) {
                            dispatch(fetchUsersList(params));
                        }
                    }
                });
            }
        } else if (payload.slug) {
            const document = {
                ...(payload.passportFront && { recto: payload.passportFront }),
                ...(payload.passportBehind && { verso: payload.passportBehind }),
                slug: payload.slug,
            };
            if (userDetail?.id) {
                await dispatch(handleSendUserDocument({ params: document, userId: `${userDetail.id}` }));
            }
        } else {
            await dispatch(
                handleUpdatedUser({
                    id: userDetail?.id,
                    password,
                    otp,
                    ...payload,
                })
            )
                .unwrap()
                .then(() => {
                    if (params) {
                        dispatch(fetchUsersList(params));
                    }
                });
        }
    };

    const handleSwitchOpenDialog = () => {
        setOpenPassword((oldOpenDialog) => {
            if (oldOpenDialog) {
                setIsHidden(false);
            }
            return !oldOpenDialog;
        });
        setShowSuccess(false);
    };

    const handleOpenPasswordDialog = () => {
        setOpenPassword(true);
        setIsHidden(true);
    };

    const handleConfirm = async ({ password, twoFactor }: { password: string; twoFactor: string | null }) => {
        await handleSubmit(password, twoFactor);
    };

    return (
        <EditUserContext.Provider
            value={{
                editingSection,
                setEditingSection,
                canRoleEdit,
                payload,
                setPayload,
                handleOpenPasswordDialog,
                onCloseDrawer,
            }}
        >
            {userDetail && (
                <Drawer
                    isOpen={isOpenDrawer}
                    isHidden={isHidden}
                    onClose={onCloseDrawer}
                    header={{
                        title: `${userDetail?.firstname} ${userDetail?.lastname}`,
                        image: {
                            avatar: userDetail?.avatar ?? undefined,
                            firstname: userDetail?.firstname,
                            lastname: userDetail?.lastname,
                        },
                    }}
                    body={<UserDetailDrawerBody />}
                />
            )}
            <PasswordValidationModal
                header={{
                    title: t("CommonUse.modify"),
                    icon: "pencil",
                }}
                successContent={{
                    image: successImage,
                    text: t<string>("CommonUse.request-taken-into-consideration"),
                }}
                asyncFromParent
                error={editUserError ?? deactivateUserError ?? archiveUserError}
                succeeded={showSuccess}
                open={openPassword}
                setOpen={handleSwitchOpenDialog}
                handleOnSubmit={handleConfirm}
            />
        </EditUserContext.Provider>
    );
}
