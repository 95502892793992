import { HistoryFilterKeys, historyFiltersChipName } from "../utils";
import { transactionStatusesFilterOptions, transactionTypesFilterOptions } from "src/shared/const/transactions";

import { useCallback, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { useTasksOptionsForWallet } from "src/modules/tasks/hooks";
import {
    getAllWalletsTransactionsCurrentPage,
    getAllWalletsTransactionsPageSize,
    getWalletTransactionFilters,
    getWalletTransactionsArchivedFilter,
    getWalletTransactionsCurrentPage,
    getWalletTransactionsEndDateFilter,
    getWalletTransactionsFilterIsTouched,
    getWalletTransactionsQueryFilter,
    getWalletTransactionsShowPageSize,
    getWalletTransactionsStartDateFilter,
    getWalletTransactionsStatusFilter,
    getWalletTransactionsTasksFilter,
    getWalletTransactionsTypeFilter,
} from "src/modules/walletsTransactions/selectors";
import {
    resetAllWalletTransactionFilters,
    resetWalletTransactionFilters,
    setAllWalletsTransactionsCurrentPage,
    setWalletTransactionCurrentPage,
    setWalletTransactionEndDateFilter,
    setWalletTransactionQueryFilter,
    setWalletTransactionStartDateFilter,
    setWalletTransactionStatusFilter,
    setWalletTransactionTaskFilter,
    setWalletTransactionTypeFilter,
    switchWalletTransactionArchivedFilter,
} from "src/modules/walletsTransactions/slice";
import {
    fetchWalletTransactions,
    handleRequestCompaniesTransactions,
} from "src/modules/walletsTransactions/thunkActions";
import { useAppDispatch, useAppSelector } from "src/store";

import { Button } from "src/shared/atoms/Buttons/Button";
import { Input } from "src/shared/atoms/Inputs/Input";
import { Select } from "src/shared/atoms/Select/Select";
import { Typography } from "src/shared/atoms/Typography/Typography";
import { Chip } from "src/shared/components/Chip/Chip";
import { CustomDatePicker } from "src/shared/components/DatePicker/DatePicker";
import { SelectMultipleOptions, SelectOption } from "src/shared/components/SelectMultipleOptions/SelectMultipleOptions";

import "./styles.scss";

type Props = {
    walletId: string;
    taskId?: string;
};

export function HistoryWalletFilters({ walletId, taskId }: Readonly<Props>) {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const [fetchWithTaskId, setFetchWithTaskId] = useState(!!taskId);
    const [closeSelect, setCloseSelect] = useState(false);

    const filters = useAppSelector((state) => getWalletTransactionFilters(state));
    const query = useAppSelector((state) => getWalletTransactionsQueryFilter(state));
    const statusFilter = useAppSelector((state) => getWalletTransactionsStatusFilter(state));
    const tasksFilter = useAppSelector((state) => getWalletTransactionsTasksFilter(state));
    const transactionFilter = useAppSelector((state) => getWalletTransactionsTypeFilter(state));
    const startDateFilter = useAppSelector((state) => getWalletTransactionsStartDateFilter(state));
    const endDateFilter = useAppSelector((state) => getWalletTransactionsEndDateFilter(state));
    const archivedFilter = useAppSelector((state) => getWalletTransactionsArchivedFilter(state));
    const isFilterTouched = useAppSelector((state) => getWalletTransactionsFilterIsTouched(state));

    const showPageSize = useAppSelector((state) => getWalletTransactionsShowPageSize(state, { walletId }) ?? 20);
    const currentPage = useAppSelector((state) => getWalletTransactionsCurrentPage(state, { walletId }));

    const allTransactionsShowPageSize = useAppSelector((state) => getAllWalletsTransactionsPageSize(state) ?? 20);
    const allWalletsTransactionsCurrentPage = useAppSelector((state) => getAllWalletsTransactionsCurrentPage(state));

    const tasksOptions = useTasksOptionsForWallet(walletId);

    useEffect(() => {
        return () => {
            dispatch(resetAllWalletTransactionFilters());
        };
    }, []);

    useEffect(() => {
        dispatch(setWalletTransactionTaskFilter({ tasks: taskId ? [{ label: "", id: taskId }] : [] }));
    }, [taskId]);

    useEffect(() => {
        if (closeSelect) {
            setCloseSelect(false);
        }
    }, [closeSelect]);

    useEffect(() => {
        const taskOption = taskId && tasksOptions.find((task) => taskId === task.id);

        if (taskId && fetchWithTaskId && taskOption) {
            setFetchWithTaskId(false);
            dispatch(setWalletTransactionTaskFilter({ tasks: [taskOption] }));
        }
    }, [dispatch, taskId, tasksOptions]);

    const handleSetArchived = useCallback(() => {
        dispatch(switchWalletTransactionArchivedFilter());
    }, [dispatch]);

    const handleSetStatusFilter = useCallback(
        (selectedItems: SelectOption[]) => {
            dispatch(setWalletTransactionStatusFilter({ status: selectedItems }));
        },
        [dispatch]
    );

    const handleSetTransactionFilter = useCallback(
        (selectedItems: SelectOption[]) => {
            dispatch(setWalletTransactionTypeFilter({ transactionType: selectedItems }));
        },
        [dispatch]
    );

    const handleSetQueryFilter = useCallback(
        (newQuery: string) => {
            dispatch(setWalletTransactionQueryFilter({ query: newQuery }));
        },
        [dispatch]
    );

    const handleSetStartDateFilter = useCallback(
        (date: Date | null) => {
            dispatch(setWalletTransactionStartDateFilter({ date: date ?? undefined }));
        },
        [dispatch]
    );

    const handleSetEndDateFilter = useCallback(
        (date: Date | null) => {
            dispatch(setWalletTransactionEndDateFilter({ date: date ?? undefined }));
        },
        [dispatch]
    );

    const handleSetTaskFilter = useCallback(
        (selectedItems: SelectOption[]) => {
            dispatch(setWalletTransactionTaskFilter({ tasks: selectedItems }));
        },
        [dispatch]
    );

    const handleValidateFilters = () => {
        setCloseSelect(true);

        if (walletId !== undefined) {
            dispatch(setWalletTransactionCurrentPage({ walletId, currentPage: 1 }));
            dispatch(
                fetchWalletTransactions({
                    walletId,
                    filters,
                    page: 1,
                    pageSize: showPageSize,
                })
            );
        } else {
            dispatch(setAllWalletsTransactionsCurrentPage({ currentPage: 1 }));
            dispatch(
                handleRequestCompaniesTransactions({
                    filters,
                    page: 1,
                    pageSize: allTransactionsShowPageSize,
                })
            );
        }
    };

    const handleResetAllFilters = useCallback(() => {
        dispatch(resetAllWalletTransactionFilters());
        setCloseSelect(true);

        if (walletId !== undefined) {
            dispatch(setWalletTransactionCurrentPage({ walletId, currentPage: 1 }));
            dispatch(
                fetchWalletTransactions({
                    walletId,
                    filters: {},
                    page: currentPage,
                    pageSize: showPageSize,
                })
            );
        } else {
            dispatch(setAllWalletsTransactionsCurrentPage({ currentPage: 1 }));
            dispatch(
                handleRequestCompaniesTransactions({
                    filters: {},
                    page: allWalletsTransactionsCurrentPage,
                    pageSize: allTransactionsShowPageSize,
                })
            );
        }
    }, [dispatch, taskId, walletId]);

    const handleResetFilter = useCallback(
        (filter: HistoryFilterKeys) => () => {
            dispatch(resetWalletTransactionFilters({ filter }));
            const { [filter]: filterToRemove, ...updatedFilters } = filters;
            setCloseSelect(true);
            if (walletId !== undefined) {
                dispatch(
                    fetchWalletTransactions({
                        walletId,
                        filters: updatedFilters,
                        page: 1,
                        pageSize: showPageSize,
                    })
                );
            } else {
                dispatch(
                    handleRequestCompaniesTransactions({
                        filters: updatedFilters,
                        page: 1,
                        pageSize: allTransactionsShowPageSize,
                    })
                );
            }
        },
        [dispatch, filters, walletId]
    );

    const renderChip = () => {
        const filtersKey = Object.keys(filters).filter(
            (filterKey) => filterKey !== "query" && filterKey !== "taskId"
        ) as HistoryFilterKeys[];

        return filtersKey.length ? (
            <div className='history-filters-chip-container'>
                {filtersKey.map((filterKey) => (
                    <div className='mr-1'>
                        <Chip
                            disabled={filterKey === "tasks" && !!taskId}
                            handleDelete={handleResetFilter(filterKey)}
                            className='fw-lighter'
                            message={t(historyFiltersChipName[filterKey])}
                        />
                    </div>
                ))}
            </div>
        ) : (
            <Typography message={t("CommonUse.filters")} />
        );
    };

    return (
        <div className='filter-history-container d-flex align-items-center'>
            <Input
                placeholder={t("CommonUse.search")}
                inputIcon='magnifying-glass'
                onChange={handleSetQueryFilter}
                value={query ?? ""}
                className='search-input'
                inputClassname='background-white'
            />
            <div className='filters-select ml-2'>
                <Select
                    fitContent
                    overflowAuto
                    forceClose={closeSelect}
                    color='white'
                    className='transaction-type-filter'
                    content={{
                        header: {
                            component: renderChip(),
                            icon: "adjustments-vertical",
                            disabled: false,
                        },
                        dropDownContent: {
                            header: {
                                title: <Typography message={t("CommonUse.filters")} />,
                                label: (
                                    <Button
                                        variant='ghost'
                                        color='lilas'
                                        onClick={handleResetAllFilters}
                                        size='sm'
                                        label={t("HistoryWalletFilters.supprimer-les-filtres")}
                                    />
                                ),
                            },
                            body: (
                                <div className='body-content'>
                                    <SelectMultipleOptions
                                        showSelectedItems={false}
                                        name={`${t("HistoryWalletFilters.par-status")} ${
                                            statusFilter?.length ? "(" + statusFilter?.length + ")" : ""
                                        }`}
                                        selected={statusFilter}
                                        items={transactionStatusesFilterOptions(t)}
                                        onSelectionChange={handleSetStatusFilter}
                                        icon='clock'
                                    />
                                    <SelectMultipleOptions
                                        showSelectedItems={false}
                                        name={`${t("HistoryWalletFilters.par-type-de-transaction")}${
                                            transactionFilter?.length ? " (" + transactionFilter?.length + ")" : ""
                                        }`}
                                        items={transactionTypesFilterOptions}
                                        selected={transactionFilter}
                                        onSelectionChange={handleSetTransactionFilter}
                                        icon='arrows-right-left-filled'
                                    />
                                    <SelectMultipleOptions
                                        showSelectedItems={false}
                                        name={`${t("HistoryWalletFilters.tasks-filter")} ${
                                            tasksFilter?.length ? "(" + tasksFilter?.length + ")" : ""
                                        }`}
                                        disabled={!tasksOptions.length || !!taskId}
                                        selected={tasksFilter}
                                        items={tasksOptions}
                                        onSelectionChange={handleSetTaskFilter}
                                        scrollable
                                        icon='task'
                                    />
                                    <CustomDatePicker
                                        startDate={startDateFilter}
                                        onChangeStartDate={handleSetStartDateFilter}
                                        endDate={endDateFilter}
                                        onChangeEndDate={handleSetEndDateFilter}
                                    />
                                    <Button
                                        icon={"archive"}
                                        label={t("HistoryWalletFilters.transaction-s-archivee-s")}
                                        onClick={handleSetArchived}
                                        variant={archivedFilter ? "primary" : "outline"}
                                    />
                                </div>
                            ),
                            footer: (
                                <div className='footer-content'>
                                    <Button
                                        className='background-neutral-200 footer-btn'
                                        variant='tertiary'
                                        onClick={handleResetAllFilters}
                                        label={t("CommonUse.cancel")}
                                    />
                                    <Button
                                        className='footer-btn'
                                        variant='primary'
                                        onClick={handleValidateFilters}
                                        label={t("CommonUse.validate")}
                                        disabled={!isFilterTouched}
                                    />
                                </div>
                            ),
                        },
                    }}
                />
            </div>
        </div>
    );
}
