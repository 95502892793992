import classnames from "classnames";
import { CreateUserContext } from "src/shared/context/createUser";

import { useContext, useEffect, useMemo, useState } from "react";

import { useTranslation } from "react-i18next";

import { getUserDetail } from "src/modules/admin/userDetail/selectors";
import { requestIfUserExist } from "src/modules/admin/userDetail/thunkActions";
import { getAuthUser } from "src/modules/auth/selectors";
import { useDebounce } from "src/shared/hooks/useDebounce";
import { useAppDispatch, useAppSelector } from "src/store";

import { InputPhone, InputPhoneValue } from "src/shared/atoms/Inputs/Phone/Phone";
import { Typography } from "src/shared/atoms/Typography/Typography";

import "./styles.scss";

export const AdminUserCreateDrawerSectionsPhone = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const { phone, setPhone, step, setStep, setUserValues, initState } = useContext(CreateUserContext);
    const { userDetail, error: userDetailError, loading: userDetailLoading } = useAppSelector(getUserDetail);

    const authUser = useAppSelector((state) => getAuthUser(state));

    useEffect(() => {
        if (authUser) {
            setPhone({ prefix: authUser?.countryCode, value: "" });
        }
        // eslint-disable-next-line
    }, [authUser?.countryCode]);

    const [error, setError] = useState("");

    const debouncedPhoneNumber = useDebounce(phone?.value, 700);

    useEffect(() => {
        if (phone?.prefix && debouncedPhoneNumber) {
            dispatch(
                requestIfUserExist({
                    phone: debouncedPhoneNumber,
                    countryCode: phone.prefix,
                })
            );
        }
        // eslint-disable-next-line
    }, [phone?.prefix, debouncedPhoneNumber]);

    useEffect(() => {
        if (!userDetailLoading) {
            if (userDetailError) {
                initState();
                setError(userDetailError);
            } else {
                if (userDetail) {
                    if (!userDetail.isAlreadyMember) {
                        setStep(1);
                        setError("");
                    } else {
                        const translatedError = t("Users.already-team-member");
                        setError(translatedError);
                    }
                } else if (phone?.value && phone?.prefix) {
                    setUserValues({ countryCode: phone.prefix, phone: phone.value });
                    setStep(1);
                }
            }
        } else {
            initState();
            setError("");
        }
        // eslint-disable-next-line
    }, [userDetail, userDetailError, userDetailLoading]);

    const [variant, underMessage] = useMemo<["success" | "error" | undefined, string]>(() => {
        if (error) {
            return ["error", t(error)];
        } else {
            return step > 0
                ? [
                      "success",
                      userDetail
                          ? t(
                                "AdminUserCreateDrawerSectionsPhone.cet-utilisateur-a-deja-un-compte-Julaya-vous-pouvez-l-attacher-a-votre-entreprise-en-lui-choisissant-un-role"
                            )
                          : "",
                  ]
                : [undefined, ""];
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [step, error, userDetail]);

    const handleChangePhone = (value: InputPhoneValue) => {
        setPhone(value);
        setUserValues({ countryCode: value.prefix, phone: value.value });
        if (userDetailError) {
            setError("");
        }
    };

    return (
        <div className='drawer-content-section drawer-create-user-phone'>
            <div className='drawer-content-section-header'>
                <Typography className='fw-bold' message={t("Users.identifier")} />
                <Typography
                    message={step > 0 ? t("CommonUse.done") : t("CommonUse.to-complete")}
                    className={classnames("fw-bold", step > 0 ? "color-success" : "color-warning-500")}
                />
            </div>
            <div className='drawer-content-section-body'>
                <InputPhone
                    autoFocus
                    label={t("CommonUse.phone-number").toString()}
                    placeholder={t("Users.enter-phone-number").toString()}
                    onChange={handleChangePhone}
                    disablePhonePrefix
                    value={phone?.value}
                    prefix={phone?.prefix}
                    variant={variant}
                    underMessage={underMessage}
                />
            </div>
        </div>
    );
};
