import { CompanyRole } from "src/shared/models/UserCompany";
import { withAuthorization } from "src/shared/utils/withAuthorization";

import { RechargementPanel } from "src/components/Rechargement/Panel";

const RechargementPageUnauthorized = () => {
    return <RechargementPanel />;
};

export const RechargementPage = withAuthorization({ allowedRoles: [CompanyRole.USER, CompanyRole.ADMIN] })(
    RechargementPageUnauthorized
);
